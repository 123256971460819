import React, { FC } from 'react';

import BaseHeader from '../../../components/Header/BaseHeaderDesktop';
import BaseHeaderMobile from '../../../components/Header/BaseHeaderMobile';
import MuiIcon from '../../../components/MUI/MuiIcon';
import useElasticSearchQuery from '../../../hooks/elasticSearch/useEsSmartViewQuery/useElasticSearchQuery';
import useParamSmartView from '../../../hooks/router/params/useParamSmartView';
import useIsMobile from '../../../hooks/useIsMobile';

/**
 * Header component on the smart views
 *
 * @returns The component itself
 */
const HeaderSmartViews: FC = () => {
  const smartViewId = useParamSmartView();
  const esQuery = useElasticSearchQuery({ id: smartViewId ?? '' });
  const isMobile = useIsMobile();

  const title = esQuery.title ?? '';

  if (isMobile) {
    return <BaseHeaderMobile backUrl={true} title={title} />;
  }

  return (
    <BaseHeader
      actionButtonRight={null}
      buttons={[]}
      description={esQuery.description}
      leadingVisual={<MuiIcon icon="filter" />}
      subtitle={null}
      title={title}
    />
  );
};

export default HeaderSmartViews;
