import { styled } from 'styled-components';

import InputFileAttachmentCommon from '../../../components/Common/InputFileAttachment';

const PADDING = '6px 8px';

/** Copy over styles used in MuiButton */
export const InputFileAttachment = styled(InputFileAttachmentCommon)`
  color: var(--color-primary-light);
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.75;
  min-width: 64px;
  padding: ${PADDING};
  position: relative;
  text-transform: none;
  user-select: none;
  vertical-align: middle;

  & > label {
    justify-content: center;
  }
`;
