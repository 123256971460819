import React, { FC } from 'react';

import useAttachmentsUpload from '../../../hooks/attachments/useAttachmentUpload';
import translate from '../../../i18n/translate';
import { ComposeNodeType } from '../../compose/props';

import * as Styled from './styled';

type Props = {
  nodeType: ComposeNodeType;
};

/**
 * Content that is rendered on the right side of the header in the compose view
 *
 * @param props          Props passed to the component
 * @param props.nodeType Whether the compose form is being used for messages or templates
 * @returns              The component itself
 */
const ContentRightAttachments: FC<Props> = ({ nodeType }) => {
  const labelAction = translate('GENERAL__ADD');
  const { inputProps } = useAttachmentsUpload(nodeType);

  return (
    <Styled.InputFileAttachment inputFileProps={inputProps}>
      {labelAction}
    </Styled.InputFileAttachment>
  );
};

export default ContentRightAttachments;
