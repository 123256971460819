/**
 * @file All permissions checks related to checklist item deletion
 */

import { MessageCreatorAndAssigned } from '../../../models/message';

import { CHECKLIST__ADMIN, CHECKLIST__DELETE } from '../permissionsAll';

import { hasPermission } from './checklistHelper';

/**
 *
 * DELETE
 *
 */

/**
 * Check whether the user has permission
 * to remove a checklist item from a new message
 *
 * @returns Whether the user has permissions
 */
export const getCanDeleteItemFromNewMessage = (): boolean => {
  // If the user can create a message, they can also remove checklist items
  return true;
};

/**
 * Check whether the user has permission
 * to remove a checklist item from an existing message
 *
 * @param message The message to check the permission for
 * @returns       Whether the user has permissions
 */
export const getCanDeleteItemFromExistingMessage = (
  message: MessageCreatorAndAssigned,
): boolean => {
  if (message.isArchived) {
    return false;
  }

  return hasPermission(CHECKLIST__DELETE) || hasPermission(CHECKLIST__ADMIN);
};
