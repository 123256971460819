import React, { FC, useState } from 'react';
import { useQuery } from 'urql';

import SkeletonColumnList from '../../components/Loading/SkeletonList';
import SmartViewsNavigation from '../../components/Navigation/SmartViews';
import SmartViewsContent from '../../components/SmartViewsContent';
import Heading from '../../containers/leftSidebarFeed/LeftSidebarFeedContent/Heading';
import {
  SearchQueriesDocument,
  SearchQueryType,
} from '../../generated/graphql';
import useIsMobile from '../../hooks/useIsMobile';
import translate from '../../i18n/translate';
import { reportError, reportInfo } from '../../services/reporting';
import { generateSmartViewsLinks } from '../../utils/smartViews';

import Error from './Error';

/**
 * Content for the SmartViews component
 *
 * @returns The component itself
 */
const SmartViewsContainer: FC = () => {
  const [{ data, fetching, error }, executeQuery] = useQuery({
    query: SearchQueriesDocument,
    variables: { type: SearchQueryType.SMART_VIEW },
  });
  const [isActive, setIsActive] = useState(true);

  const isMobile = useIsMobile();

  /**
   * Handles click/tap on reload button inside Error component
   * Tries to fetch smart views
   */
  const handleReload = () => {
    executeQuery({ requestPolicy: 'cache-and-network' });
  };

  if (fetching) {
    return <SkeletonColumnList numberOfLines={3} />;
  }

  if (error) {
    reportError(error);
    return <Error onReload={handleReload} />;
  }

  // No error, but data is null or undefined regardless
  if (!data) {
    reportInfo('No error, but also no data for the smart views');
    return null;
  }

  // If there are no smart views, we don't need to render anything
  if (data.searchQueries.length === 0) {
    return null;
  }

  /**
   * Smart views links created from the BE data
   */
  const smartViewsLinks = generateSmartViewsLinks(data.searchQueries);

  return isMobile ? (
    <>
      <Heading
        handleToggle={() => setIsActive(state => !state)}
        isActive={isActive}
        text={translate('GENERAL__SMART_VIEWS')}
      />

      <SmartViewsContent
        isActive={isActive}
        smartViewsLinks={smartViewsLinks}
      />
    </>
  ) : (
    <SmartViewsNavigation smartViewsLinks={smartViewsLinks} />
  );
};

export default SmartViewsContainer;
