/**
 * @file Topic mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorTopicMain, navigatorTopicSettings } from './navigators';

const mapperTopic = {
  // Topic is created, changed
  [NotificationEvent.TOPIC__CREATED]: navigatorTopicMain,
  [NotificationEvent.TOPIC__DESCRIPTION__UPDATED]: navigatorTopicMain,
  [NotificationEvent.TOPIC__TITLE__UPDATED]: navigatorTopicMain,

  // User or group is added to or removed from a topic
  [NotificationEvent.TOPIC__GROUP__ADDED]: navigatorTopicMain,
  [NotificationEvent.TOPIC__GROUP__REMOVED]: navigatorTopicMain,
  [NotificationEvent.TOPIC__MEMBER__ADDED]: navigatorTopicMain,
  [NotificationEvent.TOPIC__MEMBER__REMOVED]: navigatorTopicMain,

  [NotificationEvent.TOPIC__DELETED]: navigatorTopicSettings,
  [NotificationEvent.TOPIC__PRIVACY__UPDATED_TO_PRIVATE]:
    navigatorTopicSettings,
  [NotificationEvent.TOPIC__PRIVACY__UPDATED_TO_PUBLIC]: navigatorTopicSettings,
};

export default mapperTopic;
