/**
 * @file urql GraphQL auth exchange
 * Dealing with IAM auth token, auth code and redirection to login
 */

import { authExchange, AuthUtilities } from '@urql/exchange-auth';
import { Exchange } from 'urql';

import { ApiErrors } from '../../constants/errors';
import { LoginMutation, LoginMutationVariables } from '../../generated/graphql';
import { getAuthCode, goToIAM, logoutInIAM } from '../../utils/auth';
import { extractApiErrors } from '../../utils/error';
import { getHotelIdFromUrl } from '../../utils/hotel';
import { getAuthToken, setAuthToken } from '../../utils/storage/auth';
import { setIsFirstLogin } from '../../utils/storage/firstLogin';
import { setSubscriptionPaused } from '../../utils/storage/notifications';
import { getOperationName } from '../operations';
import { loginMutation } from '../schema/mutations/login';

/**
 * Error code for when the user token is invalid
 */
const codeAuthError = ApiErrors.AUTH_USER_NOT_FOUND.code;

/**
 * Refresh auth token with auth code from URL if user valid, otherwise
 * redirect to IAM login
 *
 * @param authCode  Auth code from url
 * @param utilities Auth exchange utilities
 */
const handleRefreshAuthToken = async (
  authCode: string,
  utilities: AuthUtilities,
) => {
  const { data, error } = await utilities.mutate<
    LoginMutation,
    LoginMutationVariables
  >(loginMutation, {
    code: authCode,
  });

  const apiErrors = extractApiErrors(error);

  const isUserNotFoundError = apiErrors?.some(
    err => err.code === codeAuthError,
  );

  // other api error handling is done in the error exchange
  if (isUserNotFoundError === true) {
    logoutInIAM();
  }

  const loginData = data?.login;

  if (loginData !== undefined) {
    const searchParams = new URLSearchParams(window.location.search);
    const returnUrl = searchParams.get('state');

    setAuthToken(loginData.token);
    // Clear the cookie value set during log out
    setSubscriptionPaused(false);
    setIsFirstLogin(loginData.isFirstLogin);

    /**
     * Redirect user in the app
     */
    window.location.href = returnUrl ?? window.location.origin;
    return;
  }

  if (error !== undefined) {
    const apiErrors = extractApiErrors(error);

    const isUserNotFoundError = apiErrors?.some(
      err => err.code === codeAuthError,
    );

    // other api error handling is done in the error exchange
    if (isUserNotFoundError === true) {
      logoutInIAM();
    }
  }
};

// eslint-disable-next-line @typescript-eslint/require-await
const auth: Exchange = authExchange(async utilities => {
  return {
    /**
     * Called for every operation to add authentication data to your operation
     *
     * @param operation An Operation that needs authentication tokens added
     *
     * @returns         A new Operation with added authentication tokens.
     */
    addAuthToOperation: operation => {
      const token = getAuthToken();
      const operationName = getOperationName(operation);

      // For login mutation, we don't need to have the token
      // And there's no need to attach HotelID either
      if (operationName === 'Login') {
        return operation;
      }

      if (token === null) {
        reportError('Token not found');
      }

      return utilities.appendHeaders(operation, {
        Authorization: `Bearer ${token}`,
        HotelID: getHotelIdFromUrl() ?? '',
      });
    },

    /**
     * Called after receiving an operation result to check whether it has failed with an authentication error
     *
     * @param error               A CombinedError that a result has come back with
     * @param error.graphQLErrors A list of GraphQL errors
     *
     * @returns                   Boolean value, if true, authentication must be refreshed.
     */
    didAuthError: ({ graphQLErrors }) => {
      return graphQLErrors.some(
        ({ extensions: { code } }) => code === codeAuthError,
      );
    },

    /**
     * The auth state is invalid
     * If we have auth code (IAM appends it to Hotelboard url on redirect),
     * We can get auth token from it
     *
     * If not, we need to redirect the user to IAM,
     * where they'll enter username/password
     * and we'll get the code on redirect back
     *
     * @returns Nothing
     */
    refreshAuth: async () => {
      const authCode = getAuthCode();
      const token = getAuthToken();

      if (authCode !== null) {
        await handleRefreshAuthToken(authCode, utilities);
        return;
      }

      /**
       * USER_NOT_FOUND error happened, but we have a token and no auth code.
       * This should happen only if the token is invalid
       */
      if (token !== null) {
        goToIAM();
      }
    },

    /**
     * Determine whether the auth state is invalid
     * (we're missing the auth token, so we need to reauthorize the user)
     *
     * @param operation Graphql operation
     * @returns         Whether to call refreshAuth
     */
    willAuthError: operation => {
      const operationName = getOperationName(operation);

      // For login mutation, we don't need to have the token
      if (operationName === 'Login') {
        return false;
      }

      return getAuthToken() === null;
    },
  };
});

export default auth;
