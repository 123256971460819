/**
 * @file MUI Icon mapping
 */

import AccountCircle from '@mui/icons-material/AccountCircleOutlined';
import Apartment from '@mui/icons-material/ApartmentRounded';
import AttachFile from '@mui/icons-material/AttachFileRounded';
import ChatBubbleOutline from '@mui/icons-material/ChatBubbleOutlineRounded';
import CheckCircle from '@mui/icons-material/CheckCircleOutlineRounded';
import Check from '@mui/icons-material/CheckRounded';
import ChevronLeft from '@mui/icons-material/ChevronLeftRounded';
import ChevronRight from '@mui/icons-material/ChevronRightRounded';
import ContentPasteOutlined from '@mui/icons-material/ContentPasteOutlined';
import Copyright from '@mui/icons-material/CopyrightRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import FilterList from '@mui/icons-material/FilterListRounded';
import GppMaybeOutlined from '@mui/icons-material/GppMaybeOutlined';
import GroupsOutlined from '@mui/icons-material/GroupsOutlined';
import HelpOutline from '@mui/icons-material/HelpOutlineRounded';
import Inventory2Outlined from '@mui/icons-material/Inventory2Outlined';
import LabelOutlined from '@mui/icons-material/LabelOutlined';
import LocalHotelOutlined from '@mui/icons-material/LocalHotelOutlined';
import LockOpen from '@mui/icons-material/LockOpenRounded';
import LockOutlined from '@mui/icons-material/LockOutlined';
import Logout from '@mui/icons-material/LogoutRounded';
import MessageOutlined from '@mui/icons-material/MessageOutlined';
import ModeEditOutlineOutlined from '@mui/icons-material/ModeEditOutlineOutlined';
import MoreVert from '@mui/icons-material/MoreVert';
import NotificationsNoneRounded from '@mui/icons-material/NotificationsNoneRounded';
import PersonOutline from '@mui/icons-material/PersonOutlineRounded';
import RefreshRounded from '@mui/icons-material/RefreshRounded';
import Search from '@mui/icons-material/SearchRounded';
import SettingsSuggest from '@mui/icons-material/SettingsSuggestRounded';
import Speed from '@mui/icons-material/SpeedRounded';
import SwapVerticalCircleOutlined from '@mui/icons-material/SwapVerticalCircleOutlined';
import Topic from '@mui/icons-material/TopicOutlined';
import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded';
import Tune from '@mui/icons-material/TuneRounded';
import WestRounded from '@mui/icons-material/WestRounded';

import { FC } from 'react';

import MaterialSymbolHomeIcon from './MaterialSymbolIcon/Home';
import MaterialSymbolSettingsIcon from './MaterialSymbolIcon/Settings';
import { IconPropsExtended, MuiIconName, MuiSymbolIconName } from './types';

export const muiIconMappings: Record<MuiIconName, typeof AccountCircle> = {
  apartment: Apartment,
  archive: Inventory2Outlined,
  arrowLeft: WestRounded,
  arrowRight: EastRoundedIcon,
  attachFile: AttachFile,
  changeObject: SwapVerticalCircleOutlined,
  chatBubble: ChatBubbleOutline,
  check: Check,
  checkCircle: CheckCircle,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  copyright: Copyright,
  devPanel: SettingsSuggest,
  edit: ModeEditOutlineOutlined,
  filter: FilterList,
  help: HelpOutline,
  hotel: LocalHotelOutlined,
  label: LabelOutlined,
  lock: LockOutlined,
  lockOpen: LockOpen,
  logout: Logout,
  more: MoreVert,
  notification: NotificationsNoneRounded,
  privacy: GppMaybeOutlined,
  profile: AccountCircle,
  refresh: RefreshRounded,
  replies: MessageOutlined,
  search: Search,
  speed: Speed,
  template: ContentPasteOutlined,
  topic: Topic,
  translate: TranslateRoundedIcon,
  tune: Tune,
  user: PersonOutline,
  userGroup: GroupsOutlined,
};

export const mappingMaterialSymbolIcon: Record<
  MuiSymbolIconName,
  FC<IconPropsExtended>
> = {
  home: MaterialSymbolHomeIcon,
  settings: MaterialSymbolSettingsIcon,
};

export type IconName = MuiIconName | MuiSymbolIconName;
