/**
 * @file Contains customization for MuiAvatar Component
 */

import { Components } from '@mui/material';

import { colorNeutral, colorPrimary } from '../colors';

const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    disableRipple: true, // issues with how the ripple looks like when custom styles applied due to border radius
  },
  styleOverrides: {
    root: {
      borderRadius: 100,
      fontWeight: 600,
      textTransform: 'none',
    },
  },
  variants: [
    {
      props: { color: 'primary' },
      style: {
        '&:focus-visible': {
          backgroundColor: colorPrimary[100],
        },
        '&:hover': {
          backgroundColor: colorPrimary[50],
        },
      },
    },
    {
      props: { color: 'primary', variant: 'contained' },
      style: {
        '&:focus-visible': {
          backgroundColor: colorPrimary[700],
        },
        '&:hover': {
          backgroundColor: colorPrimary[800],
        },
      },
    },
    {
      props: { color: 'neutral' },
      style: {
        '&:focus-visible': {
          backgroundColor: colorNeutral[500],
        },
        '&:hover': {
          backgroundColor: colorNeutral[400],
        },
        backgroundColor: colorNeutral[300],
        color: colorNeutral[800],
      },
    },
  ],
};

export default MuiButton;
