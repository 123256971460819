import { Button as MuiButton, styled } from '@mui/material';

export const Button = styled(MuiButton)(() => ({
  '& > .MuiButton-endIcon': {
    marginLeft: 'var(--spacing-tiny)',
  },
  '& > .MuiButton-startIcon': {
    marginRight: 'var(--spacing-tiny)',
  },
  '&:hover,:active': {
    boxShadow: 'none',
  },
  boxShadow: 'none',
  fontWeight: 'var(--font-medium)',
  height: '2.25rem',
  textTransform: 'uppercase',
  width: '5.5rem',
}));
