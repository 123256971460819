/**
 * @file Register service worker
 */

import { useEffect, useState } from 'react';

import { checkServiceWorkerSupport, register } from '../serviceWorker';
import { reportError, reportInfo } from '../services/reporting';

/**
 * Report an error initializing the service worker
 *
 * @returns Void
 */
const reportErrorInit = () => reportError('Error initializing service worker');

/**
 * Get the most current service worker
 *
 * @param registration Service worker container
 * @returns            Valid service worker, if exists
 */
const extractServiceWorker = (
  registration: ServiceWorkerRegistration | null,
): ServiceWorker | null => {
  if (registration === null) {
    reportInfo('ServiceWorker: undefined');
    return null;
  } else if (registration.installing !== null) {
    reportInfo('ServiceWorker: installing');
    return registration.installing;
  } else if (registration.waiting !== null) {
    reportInfo('ServiceWorker: waiting');
    return registration.waiting;
  } else if (registration.active !== null) {
    reportInfo('ServiceWorker: active');
    return registration.active;
  }

  reportInfo('Unknown service worker state');
  return null;
};

/**
 * Only attach events once
 * Because register is async, it initService worker needs to be wrapper,
 * meaning we can't easily utilize effect hook cleanup function
 */
let listenersAttached = false;

/**
 * Hook for using service worker
 * Will automatically register a new one once old one is unloaded
 *
 * @returns Current service worker and its state
 */
const useServiceWorker = (): ServiceWorkerState | null => {
  const [state, setState] = useState<ServiceWorkerState | null>(null);

  useEffect(() => {
    /**
     * Init the service worker
     */
    const initServiceWorker = async () => {
      const registration = await register();

      const serviceWorker = extractServiceWorker(registration);
      setState(serviceWorker?.state ?? null);

      /**
       * Service worker which controls this page was changed
       *
       * @param event The event that took place
       */
      const handleControllerChange = (event: Event) => {
        const container = event.currentTarget as ServiceWorkerContainer;

        const { controller } = container;
        setState(controller?.state ?? null);

        /**
         * Service worker's state has changed
         * (values are from ServiceWorkerState)
         */
        const handleStateChange = () => {
          const changedState = controller?.state ?? null;
          setState(changedState);

          /**
           * Service worker is no longer in use,
           * so we initialize another one
           */
          if (changedState === 'redundant') {
            initServiceWorker().catch(reportErrorInit);
          }
        };

        controller?.addEventListener('statechange', handleStateChange, false);
      };

      if (listenersAttached === false) {
        listenersAttached = true;
        navigator.serviceWorker.addEventListener(
          'controllerchange',
          handleControllerChange,
          false,
        );
      }
    };

    // Wrapper like this because register is async
    // init only if supported
    if (checkServiceWorkerSupport()) {
      initServiceWorker().catch(reportErrorInit);
    }
  }, []);

  return state;
};

export default useServiceWorker;
