import { AvatarProps, Tooltip } from '@mui/material';
import React, { FC } from 'react';

import { User, UserStatus } from '../../../generated/graphql';
import getAvatarAltText from '../../../utils/getAvatarAltText';
import { memberGetImageUrl } from '../../../utils/imageUrlGet';
import userGetInactive from '../../../utils/user/userGetInactive';
import userGetInitials from '../../../utils/user/userGetInitials';
import userGetName from '../../../utils/user/userGetName';
import userGetPending from '../../../utils/user/userGetPending';

import MuiIcon from '../MuiIcon';

import * as Styled from './styled';

type Props = {
  user: Pick<User, 'firstName' | 'imageUrl' | 'lastName' | 'status'>;
};

/**
 * Avatar component
 *
 * @param props      Props passed to the component
 * @param props.user The user to generate the avatar for
 * @returns          The component itself
 */
const MuiAvatar: FC<Props> = ({ user }) => {
  const { firstName, lastName, imageUrl, status } = user;

  // src could be an absolute path, but will usually be relative,
  // and that needs to be prefixed with Media Library API URL,
  // so we call this to check what we got and format it properly
  const srcAbsolute = memberGetImageUrl(imageUrl);
  const isDeleted = status === UserStatus.DELETED;
  // Used for avatar styles shared between deleted, deactivated and not assigned users
  const isInactive = userGetInactive(status);

  // Check if the user is in pending status
  const isPending = userGetPending(status);

  const props: AvatarProps =
    srcAbsolute !== null
      ? { alt: getAvatarAltText(user), src: srcAbsolute }
      : {};

  return (
    <Tooltip title={userGetName(user)}>
      <Styled.MuiAvatar
        {...props}
        data-is-inactive={isInactive}
        data-is-pending={isPending}
        variant="circular"
      >
        {isDeleted === true ? (
          <MuiIcon icon="user" />
        ) : (
          userGetInitials(firstName, lastName)
        )}
      </Styled.MuiAvatar>
    </Tooltip>
  );
};

export default MuiAvatar;
