import Tippy from '@tippyjs/react';
import React, { FC } from 'react';

import translate from '../../../../../i18n/translate';
import { InputFileProps } from '../../../../../models/attachment';
import postPermissionCameraEvent from '../../../../../native-messaging/handlers/web/permissionCamera';
import { getIsReactNativeAndroid } from '../../../../../utils/webview/helpers';
import Icon from '../../../Icon';
import { NAMES } from '../ButtonControl';
import { getIconColor } from '../ButtonControl/helpers';

import * as Styled from './styled';

export type Props = {
  inputFileProps: InputFileProps;
};

const iconName = NAMES.COMPOSE__CONTROL_ATTACH_FILE;

/**
 * Make control buttons same height as send button so there is no different in height
 * between comment external/internal on desktop.
 */
const ICON_SIZE = 19;

/**
 * The button for attaching a file
 *
 * @param props                Props passed to the component
 * @param props.inputFileProps Props passed to the <input type="file" />
 * @returns                    The component itself
 */
const ButtonAttachFile: FC<Props> = ({ inputFileProps }) => {
  const label = translate('COMPOSE__ATTACH__FILE');
  const isReactNativeAndroid = getIsReactNativeAndroid();

  if (isReactNativeAndroid) {
    postPermissionCameraEvent();
  }

  return (
    <Tippy content={label} touch={false}>
      <span>
        <Styled.InputFileAttachment inputFileProps={inputFileProps}>
          <Icon
            color={`var(${getIconColor(false)})`}
            height={ICON_SIZE}
            name={iconName}
            width={ICON_SIZE}
          />
        </Styled.InputFileAttachment>
      </span>
    </Tippy>
  );
};

export default ButtonAttachFile;
