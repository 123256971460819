/**
 * @file messageCreate mutation resolver
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  MeDocument,
  MeQuery,
  MeQueryVariables,
  MessageCreateMutation,
  MessageCreateMutationVariables,
  MessagesDocument,
  Node,
} from '../../../../../generated/graphql';

import { addOrUpdateTask } from '../utils/updaters/messages/tasks';
import paginatedQueryCreation from '../utils/updaters/paginatedQueryCreation';
import {
  addNodeToPaginatedQuery,
  filterQueries,
} from '../utils/updaters/paginatedQueryHelpers';

/**
 * After successful create request this function add message to graphql cache.
 *
 * @param result               The result of the mutation
 * @param result.messageCreate The result of the messageCreate mutation
 * @param args                 Args passed to the mutation
 * @param cache                Current GraphQL cache
 */
const messageCreateMutation: UpdateResolver<
  MessageCreateMutation,
  MessageCreateMutationVariables
> = ({ messageCreate }, args, cache) => {
  const { topic: topicId } = args.data;

  cache.updateQuery<MeQuery, MeQueryVariables>(
    { query: MeDocument, variables: {} },
    data => {
      if (data === null) {
        return null;
      }

      data.me.hasMessages = true;

      return data;
    },
  );

  paginatedQueryCreation({
    cache,
    filterQueries: filterQueries({
      filterById: topicId,
      parentIdName: 'topicId',
      queryName: 'messages',
    }),
    /**
     * Add node to paginated query
     *
     * @param variables Variables used in the query
     *
     * @returns         void
     */
    updateQuery: variables =>
      addNodeToPaginatedQuery({
        cache,
        node: messageCreate as Node,
        query: MessagesDocument,
        queryName: 'messages',
        variables,
      }),
  });

  addOrUpdateTask(messageCreate, cache);
};

export default messageCreateMutation;
