/**
 * @file Checklist proxy links mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorMessage } from './navigators';

const mapperChecklist = {
  // Checklist item created
  [NotificationEvent.CHECKLIST_ITEM__CREATED]: navigatorMessage,
  [NotificationEvent.CHECKLIST_ITEM__CREATED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.CHECKLIST_ITEM__CREATED__FOR_CREATOR]: navigatorMessage,

  // Checklist item updated
  [NotificationEvent.CHECKLIST_ITEM__CHECKED]: navigatorMessage,
  [NotificationEvent.CHECKLIST_ITEM__CHECKED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.CHECKLIST_ITEM__CHECKED__FOR_CREATOR]: navigatorMessage,
  [NotificationEvent.CHECKLIST_ITEM__UNCHECKED]: navigatorMessage,
  [NotificationEvent.CHECKLIST_ITEM__UNCHECKED__FOR_ASSIGNEES]:
    navigatorMessage,
  [NotificationEvent.CHECKLIST_ITEM__UNCHECKED__FOR_CREATOR]: navigatorMessage,

  // Checklist item deleted
  [NotificationEvent.CHECKLIST_ITEM__DELETED]: navigatorMessage,
  [NotificationEvent.CHECKLIST_ITEM__DELETED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.CHECKLIST_ITEM__DELETED__FOR_CREATOR]: navigatorMessage,
};

export default mapperChecklist;
