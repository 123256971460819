/**
 * @file contains configurations for app svg icons
 */

import Icon from '../models/icon';

const NAMES: Record<Required<Icon>, string> = {
  ATTACHMENT__FILE: 'attachment__file',
  ATTACHMENT__IMAGE: 'attachment__image',
  ATTACHMENT__PDF: 'attachment__pdf',
  ATTACHMENT__PREVIEW: 'attachment__preview',

  BOTTOM_NAV__COMPOSE: 'bottom_nav__compose',

  COMPOSE__CONTROL_ATTACH_FILE: 'compose__style_attach_file',
  COMPOSE__CONTROL_LIST_BULLETS: 'compose__style_list_bullets',
  COMPOSE__CONTROL_LIST_NUMBERS: 'compose__style_list_numbers',
  COMPOSE__CONTROL_SEND: 'compose__send',
  COMPOSE__CONTROL_TEXT_BOLD: 'compose__style_text_bold',
  COMPOSE__CONTROL_TEXT_ITALIC: 'compose__style_text_italic',
  COMPOSE__CONTROL_TEXT_STRIKETHROUGH: 'compose__style_text_strikethrough',
  COMPOSE__LABEL_EDIT: 'general__edit',

  CONCIERGE__CHECKBOX_CHECKED: 'concierge__checkbox_checked',
  CONCIERGE__CHECKBOX_UNCHECKED: 'concierge__checkbox_unchecked',
  CONCIERGE__EMOJI_1_FULL: 'concierge__emoji_1_full',
  CONCIERGE__EMOJI_2_FULL: 'concierge__emoji_2_full',
  CONCIERGE__EMOJI_3_FULL: 'concierge__emoji_3_full',
  CONCIERGE__MAIN: 'concierge__main',
  CONCIERGE__STAR_FULL: 'concierge__star_full',
  CONCIERGE__STAR_OUTLINE: 'concierge__star_outline',
  CONCIERGE__TIME_PICKER: 'concierge__time_picker',
  CONCIERGE__TIME_PICKER_OUTLINE: 'concierge__time_picker_outline',

  GENERAL__ADD: 'general__add',
  GENERAL__ARCHIVE: 'general__archive',
  GENERAL__ARROW: 'general__arrow',
  GENERAL__AVATAR: 'general__avatar',
  GENERAL__CHECKBOX_CHECKED: 'general__checkbox_checked',
  GENERAL__CHECKBOX_INDETERMINATE: 'general__checkbox_unchecked',
  GENERAL__CHECKBOX_UNCHECKED: 'general__checkbox_unchecked',
  GENERAL__CHEVRON: 'general__chevron',
  GENERAL__CLOCK: 'general__clock',
  GENERAL__CLOSE: 'general__close',
  GENERAL__DASH: 'general__dash',
  GENERAL__DOWNLOAD: 'general__download',
  GENERAL__FULL_SCREEN_OFF: 'general__full_screen_off',
  GENERAL__FULL_SCREEN_ON: 'general__full_screen_on',
  GENERAL__LOGO__SPLASH: 'general__logo__splash',
  GENERAL__PLUS: 'general__plus',
  GENERAL__RADIO_CHECKED: 'general__radio_checked',
  GENERAL__RADIO_UNCHECKED: 'general__radio_unchecked',
  GENERAL__REPLY: 'general__reply',
  GENERAL__TRASH: 'general__trash',
  GENERAL__TRIANGLE: 'general__triangle',

  HOTEL__LOGO_PLACEHOLDER: 'hotel__logo_placeholder',

  MESSAGE__INFO__ASSIGNEE: 'message__info__assignee',
  MESSAGE__INFO__CHECKBOX: 'message__info__checkbox',
  MESSAGE__INFO__GROUPS: 'message__info__groups',

  TOAST__BELL: 'toast__bell',
  TOAST__CLOSE: 'toast__close',
  TOAST__EXCLAMATION_MARK: 'toast__exclamation_mark',
};

export type Segments = 'fill' | 'stroke';

const fillStrokeMapping = new Map<string, Set<Segments>>([
  [NAMES.ATTACHMENT__FILE, new Set(['stroke'])],
  [NAMES.ATTACHMENT__IMAGE, new Set(['stroke'])],
  [NAMES.ATTACHMENT__PDF, new Set(['stroke'])],
  [NAMES.ATTACHMENT__PREVIEW, new Set(['stroke'])],

  [NAMES.BOTTOM_NAV__COMPOSE, new Set()],

  [NAMES.COMPOSE__CONTROL_ATTACH_FILE, new Set(['fill', 'stroke'])],
  [NAMES.COMPOSE__CONTROL_LIST_BULLETS, new Set(['fill', 'stroke'])],
  [NAMES.COMPOSE__CONTROL_LIST_NUMBERS, new Set(['fill', 'stroke'])],
  [NAMES.COMPOSE__CONTROL_SEND, new Set(['fill', 'stroke'])],
  [NAMES.COMPOSE__CONTROL_TEXT_BOLD, new Set(['fill', 'stroke'])],
  [NAMES.COMPOSE__CONTROL_TEXT_ITALIC, new Set(['fill', 'stroke'])],
  [NAMES.COMPOSE__CONTROL_TEXT_STRIKETHROUGH, new Set(['fill', 'stroke'])],
  [NAMES.COMPOSE__LABEL_EDIT, new Set(['fill'])],

  [NAMES.CONCIERGE__CHECKBOX_CHECKED, new Set()],
  [NAMES.CONCIERGE__CHECKBOX_UNCHECKED, new Set()],
  [NAMES.CONCIERGE__EMOJI_1_FULL, new Set()],
  [NAMES.CONCIERGE__EMOJI_2_FULL, new Set()],
  [NAMES.CONCIERGE__EMOJI_3_FULL, new Set()],
  [NAMES.CONCIERGE__MAIN, new Set(['stroke'])],
  [NAMES.CONCIERGE__STAR_FULL, new Set()],
  [NAMES.CONCIERGE__STAR_OUTLINE, new Set()],
  [NAMES.CONCIERGE__TIME_PICKER, new Set(['fill', 'stroke'])],
  [NAMES.CONCIERGE__TIME_PICKER_OUTLINE, new Set(['stroke'])],

  [NAMES.GENERAL__ARCHIVE, new Set(['stroke'])],
  [NAMES.GENERAL__ADD, new Set(['stroke'])],
  [NAMES.GENERAL__ARROW, new Set(['stroke'])],
  [NAMES.GENERAL__AVATAR, new Set(['stroke'])],
  [NAMES.GENERAL__CHECKBOX_CHECKED, new Set(['stroke'])],
  [NAMES.GENERAL__CHECKBOX_INDETERMINATE, new Set(['stroke'])],
  [NAMES.GENERAL__CHECKBOX_UNCHECKED, new Set(['stroke'])],
  [NAMES.GENERAL__CHEVRON, new Set(['fill', 'stroke'])],
  [NAMES.GENERAL__CLOCK, new Set(['fill', 'stroke'])],
  [NAMES.GENERAL__CLOSE, new Set(['stroke'])],
  [NAMES.GENERAL__DASH, new Set(['stroke'])],
  [NAMES.GENERAL__DOWNLOAD, new Set(['stroke'])],
  [NAMES.GENERAL__FULL_SCREEN_OFF, new Set(['stroke'])],
  [NAMES.GENERAL__FULL_SCREEN_ON, new Set(['stroke'])],
  [NAMES.GENERAL__PLUS, new Set(['stroke'])],
  [NAMES.GENERAL__RADIO_CHECKED, new Set(['fill', 'stroke'])],
  [NAMES.GENERAL__RADIO_UNCHECKED, new Set(['stroke'])],
  [NAMES.GENERAL__REPLY, new Set(['stroke'])],
  [NAMES.GENERAL__TRASH, new Set(['stroke'])],
  [NAMES.GENERAL__TRIANGLE, new Set(['fill', 'stroke'])],

  [NAMES.HOTEL__LOGO_PLACEHOLDER, new Set(['stroke'])],

  [NAMES.MESSAGE__INFO__ASSIGNEE, new Set(['fill', 'stroke'])],
  [NAMES.MESSAGE__INFO__CHECKBOX, new Set(['fill', 'stroke'])],
  [NAMES.MESSAGE__INFO__GROUPS, new Set(['fill', 'stroke'])],

  [NAMES.TOAST__BELL, new Set(['fill', 'stroke'])],
  [NAMES.TOAST__CLOSE, new Set(['fill', 'stroke'])],
  [NAMES.TOAST__EXCLAMATION_MARK, new Set(['fill', 'stroke'])],
]);

export { NAMES as default, fillStrokeMapping };
