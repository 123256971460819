/**
 * @file contains hook used for pull to refresh functionality
 */

import { TouchEvent, useRef, useState } from 'react';

import postPullToRefresh from '../native-messaging/handlers/web/pullToRefresh';
import { getIsReactNativeAndroid } from '../utils/webview/helpers';

// the required distance between touchStart and touchEnd to trigger the refresh
const MIN_REFRESH_DISTANCE = 50;
// the required distance between touchStart and touchEnd to be detected as a swipe
const MIN_SWIPE_DISTANCE = 15;

/**
 * Handle the touch events for the pull to refresh functionality
 * (Only used inside of webview on Android)
 *
 * @returns The touch events
 */
const usePullToRefresh = () => {
  const isWebviewAndroid = getIsReactNativeAndroid();

  const touchStart = useRef<number | null>(null);
  const touchEnd = useRef<number | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [shouldShowIndicator, setShouldShowIndicator] = useState(false);

  /**
   * Pull to refresh is handled natively on iOS and in browsers,
   * so we are only handling it in the webview on Android.
   * We are returing undefined because we don't want to handle the touch events.
   */
  if (isWebviewAndroid === false) {
    return {
      isRefreshing,
      onTouchEnd: undefined,
      onTouchMove: undefined,
      onTouchStart: undefined,
      shouldShowIndicator,
    };
  }

  /**
   * Handle the touch start event
   *
   * @param event Touch event
   */
  const onTouchStart = (event: TouchEvent) => {
    touchEnd.current = null;
    touchStart.current = event.targetTouches[0].clientY;
  };

  /**
   * If vertical swipe is enough, set the refreshing state
   *
   * @param event Touch event
   */
  const onTouchMove = (event: TouchEvent) => {
    if (isRefreshing) {
      return;
    }

    touchEnd.current = event.targetTouches[0].clientY;

    if (touchStart.current === null || touchEnd.current === null) {
      return;
    }

    const distance = touchStart.current - touchEnd.current;

    setShouldShowIndicator(distance < -MIN_SWIPE_DISTANCE);
    setIsRefreshing(distance < -MIN_REFRESH_DISTANCE);
  };

  /**
   * If distance is enough, post pullToRefresh event to the native side and reset the state
   */
  const onTouchEnd = () => {
    if (isRefreshing) {
      postPullToRefresh();
      setIsRefreshing(false);
    }
  };

  return {
    isRefreshing,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    shouldShowIndicator,
  };
};

export default usePullToRefresh;
