/**
 * @file contains app-wide preferences
 */

import { AUTH__EXPIRATION_IN_DAYS } from '../config-common';
import { MessageStatus, TimeUnit } from '../generated/graphql';

/**
 * The max visible length of the group/label titles shown on kanban cards
 */
export const KANBAN__CHIP__MAX_TEXT_LENGTH = 12;

/**
 * The number of user groups/labels to show on kanban cards
 */
export const KANBAN__CHIPS_SHOWN = 2;

/**
 * The maximum number of avatars we want to show, before adding a +n avatar
 */
export const KANBAN__MAX_AVATARS_TO_SHOW = 3;

/**
 * The order of task sections
 */
export const KANBAN__SECTIONS_ORDER = [
  MessageStatus.OPEN,
  MessageStatus.IN_PROGRESS,
  MessageStatus.RESOLVED,
] as const;

/**
 * The max visible length of the mobile header title
 */
export const MOBILE_HEADER__MAX_TITLE_LENGTH = 10;

/**
 * Shared cookie options for all cookies
 */
export const PREFERENCE_COOKIE_OPTIONS_COMMON = {
  sameSite: 'None',
  secure: true,
} as const;

/**
 * Used for first-party cookies
 */
export const PREFERENCE_COOKIE_OPTIONS_INTERNAL = {
  ...PREFERENCE_COOKIE_OPTIONS_COMMON,
  expires: AUTH__EXPIRATION_IN_DAYS,
} as const;

/**
 * Used for ROQ cookies
 */
export const PREFERENCE_COOKIE_OPTIONS_ROQ = {
  ...PREFERENCE_COOKIE_OPTIONS_COMMON,
  expires: AUTH__EXPIRATION_IN_DAYS,
} as const;

// Determines how we should show dates in the app
// (dd.mm.yyy. format)
export const PREFERENCE_DATE_FORMAT = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
} as const;

// Determines how we should show date-times in the app
// (dd.mm.yyy. hh:mm format)
export const PREFERENCE_DATETIME_FORMAT = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',

  // Don't sort alphabetically, but separate date from time
  // eslint-disable-next-line sort-keys
  hour: '2-digit',
  minute: '2-digit',
} as const;

/**
 * Left sidebar badge number limit
 */
export const PREFERENCE_BADGE_LIMIT = 9;

/**
 * Used to distinguish Firebase client types
 */
export const PREFERENCE_DEVICE_TYPE = 'web';

// Base url for images
export const PREFERENCE_IMAGES_BASE = 'assets/images/';

// Base url for dashboard images
export const PREFERENCE_IMAGES_DASHBOARD_BASE =
  PREFERENCE_IMAGES_BASE + 'dashboard/';

// Base url for illustrations
export const PREFERENCE_IMAGES_ILLUSTRATIONS_BASE =
  PREFERENCE_IMAGES_BASE + 'illustrations/';

// Base url for images used in modals
export const PREFERENCE_IMAGES_MODALS_BASE = PREFERENCE_IMAGES_BASE + 'modals/';

// Determines how we should show date in the message due date section
// (MONTH DD format)
export const PREFERENCE_MESSAGE_DUE_DATE_FORMAT = {
  day: 'numeric',
  month: 'long',
} as const;

// The amount of time to wait before making an API request to mark messages read
export const PREFERENCE_MESSAGE_READ_DEBOUNCE = 300;

// The amount of time the user has to be looking at a message
// in order to be considered as read
export const PREFERENCE_MESSAGE_READ_DELAY = 1000;

/**
 * Placeholder used inside of translation strings that should contain dynamic strings
 */
export const PREFERENCE_PLACEHOLDER_SYMBOL = '$';

/**
 * Default reminder value
 */
export const PREFERENCE_REMINDER_DEFAULT = {
  amount: 30,
  unit: TimeUnit.MINUTES,
} as const;

/**
 * List of reminders options
 */
export const PREFERENCE_REMINDERS_LIST = [
  { amount: -1, unit: TimeUnit.DAYS },
  { amount: -1, unit: TimeUnit.HOURS },
  { amount: 0, unit: TimeUnit.MINUTES },
  { amount: 10, unit: TimeUnit.MINUTES },
  { amount: 30, unit: TimeUnit.MINUTES },
  { amount: 1, unit: TimeUnit.HOURS },
  { amount: 2, unit: TimeUnit.HOURS },
  { amount: 1, unit: TimeUnit.DAYS },
] as const;

/**
 * Debounce timer for scroll, in ms
 */
export const PREFERENCE_SCROLL_TIMEOUT = 100;

// Determines how we should show times in the app
// (hh:mm format)
export const PREFERENCE_TIME_FORMAT = {
  hour: '2-digit',
  minute: '2-digit',
} as const;

// Refresh "x seconds/minutes/hours... ago" interval (in ms)
export const PREFERENCE_TIME_REFRESH = 1000 * 60;

/**
 * Default value for the scroll on the feed
 */
export const SCROLL__DEFAULT = 0;

/**
 * Pattern for search input
 */
export const SEARCH__INPUT_PATTERN = '^(?!\\.$)(?!^\\.\\.$).*';

/**
 * Preference for table rows number in settings page when loading
 */
export const SETTINGS__LOADING_ROWS_NUMBER = 4;

/**
 * The size of the toast icon, in px
 */
export const TOAST__ICON_SIZE = 40;

/**
 * Toast Message duration (ms)
 */
export const TOAST__MESSAGE_DURATION = 5000;

/**
 * Toast Message description length
 */
export const TOAST__MESSAGE_DESCRIPTION_LENGTH = 350;

/**
 * Toast Message details length
 */
export const TOAST__MESSAGE_DETAILS_LENGTH = 350;

/**
 * Toast Message title length
 */
export const TOAST__MESSAGE_TITLE_LENGTH = 100;
