/**
 * @file Post event to native app to trigger pull to refresh
 */
import { REQUEST__PULL_TO_REFRESH } from '../../constants';
import { postMessageToNative } from '../../helpers/postMessageToNative';

/**
 * Post event to native app to trigger pull to refresh (Android only)
 */
const postPullToRefresh = (): void => {
  postMessageToNative({
    eventName: REQUEST__PULL_TO_REFRESH,
  });
};

export default postPullToRefresh;
