/**
 * @file contains functions that handle global data storing
 */

import {
  Hotel,
  HotelPermissions,
  HotelStatus,
  MeQuery,
  Scalars,
} from '../../generated/graphql';
import { reportError, setBugsnagUserId } from '../../services/reporting';

let meData: MeQuery['me'] | null = null;
let hotelCurrentId: Scalars['ID']['output'] | null = null;

/**
 * Get the hotel permission that the current user has
 * For the currently open hotel
 *
 * @returns The hotelPermission that the user has
 */
const getCurrentHotelInfo = (): HotelPermissions | null => {
  if (meData === null || hotelCurrentId === null) {
    reportError(
      'getCurrentHotelInfo: Reading user current before initializing it.',
    );
    return null;
  }
  const info = meData.hotelPermissions.find(
    row => row.hotel.id === hotelCurrentId,
  );

  if (info === undefined) {
    reportError(
      `getCurrentHotelInfo: Hotel permission not found for current hotel: ${hotelCurrentId}.`,
    );
    return null;
  }

  return info;
};

/**
 * Get permissions that the current user has
 * For the currently open hotel
 *
 * @returns The permissions that the user has (values from permissionsAll)
 */
export const getPermissionsCurrent = (): string[] => {
  const info = getCurrentHotelInfo();

  return info?.permissions ?? [];
};

/**
 * Get the current user info
 *
 * @returns The current user, if set or null if not
 */
export const getUserCurrent = (): MeQuery['me'] | null => {
  if (meData === null) {
    reportError('getUserCurrent: Reading user current before initializing it.');
  }

  return meData;
};

/**
 * Set the current user info
 *
 * @param data The user info
 */
export const setUserCurrent = (data: MeQuery['me']): void => {
  meData = data;
  setBugsnagUserId(meData.id);
};

/**
 * Set the ID for which hotel is the current board for
 *
 * @param hotelId The ID of the current hotel
 */
export const setHotelCurrentId = (hotelId: Hotel['id']): void => {
  hotelCurrentId = hotelId;
};

/**
 * Get the currently viewed hotel
 *
 * @returns The hotel
 */
export const getHotelCurrent = (): Hotel | null => {
  if (meData === null || hotelCurrentId === null) {
    reportError(
      'getHotelCurrent: Reading user current before initializing it.',
    );
    return null;
  }

  const permissions = meData.hotelPermissions.find(data => {
    return data.hotel.id === hotelCurrentId;
  });

  return permissions?.hotel ?? null;
};

/**
 * Get the status of the currently viewed hotel
 *
 * @returns The hotel status
 */
export const getHotelStatus = (): HotelStatus | null => {
  if (meData === null || hotelCurrentId === null) {
    reportError('getHotelStatus: Reading user current before initializing it.');
    return null;
  }

  const permissions = meData.hotelPermissions.find(data => {
    return data.hotel.id === hotelCurrentId;
  });

  return permissions?.hotel.status ?? null;
};

/**
 * Get whether the hotel is a basic one
 * (has reduced functionality)
 *
 * @returns Whether the hotel is basic
 */
export const getIsBasic = (): boolean => {
  return getHotelStatus() === HotelStatus.BASIC;
};
