/**
 * @file Comment proxy links mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorComment } from './navigators';

const mapperComment = {
  [NotificationEvent.COMMENT__CREATED]: navigatorComment,
  [NotificationEvent.COMMENT__CREATED__FOR_ASSIGNEES]: navigatorComment,
  [NotificationEvent.COMMENT__CREATED__FOR_CREATOR]: navigatorComment,
  [NotificationEvent.COMMENT__EXTERNAL_CREATED]: navigatorComment,
  [NotificationEvent.COMMENT__EXTERNAL_CREATED__FOR_ASSIGNEES]:
    navigatorComment,
};

export default mapperComment;
