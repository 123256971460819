import React, { FC } from 'react';

import ErrorWrapper from '../../../../../components/Errors/ErrorWrapper';
import { ILLUSTRATION__INFO } from '../../../../../constants/illustrations';
import { MODAL__TOPIC_CREATE } from '../../../../../constants/routeModalParams';
import { useModal } from '../../../../../containers/modals/useModal';
import useParamHotelId from '../../../../../hooks/router/params/useParamHotelId';
import useIsMobile from '../../../../../hooks/useIsMobile';
import translate from '../../../../../i18n/translate';
import { getCanCreateTopic } from '../../../../../utils/permissions/topic';
import { getHomeUrl } from '../../../../urls/home';

import * as Styled from './styled';

type Props = {
  shouldShowHomeButton: boolean;
};

/**
 * When there is no topic selected but user is on the /feed
 *
 * @param props                      Props passed to the component
 * @param props.shouldShowHomeButton Whether there are topics or not
 * @returns                          The component itself
 */
const FeedTopicMissing: FC<Props> = ({ shouldShowHomeButton }) => {
  const hotelId = useParamHotelId();
  const isMobile = useIsMobile();
  const { openModal } = useModal();

  return (
    <ErrorWrapper
      actionButton={
        getCanCreateTopic()
          ? {
              /**
               * Open the modal for creating a new topic
               *
               * @returns void
               */
              onClick: () => openModal(MODAL__TOPIC_CREATE, { redirect: true }),
              text: translate('FEED__NO_TOPIC_SELECTED__CREATE_TOPIC_LINK'),
            }
          : null
      }
      description={
        isMobile
          ? translate('FEED__NO_TOPIC_SELECTED__DESCRIPTION__MOBILE')
          : translate('FEED__NO_TOPIC_SELECTED__DESCRIPTION')
      }
      illustrationName={ILLUSTRATION__INFO}
      isFullScreen={false}
      title={translate('GENERAL__OOPS')}
    >
      {isMobile && shouldShowHomeButton && (
        <Styled.Link to={getHomeUrl(hotelId)}>
          {translate('FEED__NO_TOPIC_SELECTED__HOME_LINK')}
        </Styled.Link>
      )}
    </ErrorWrapper>
  );
};

export default FeedTopicMissing;
