/**
 * @file contains helper functions for checklist permission check
 */

import { PermissionsChecklist } from '../../../models/permissions';
import { getPermissionsCurrent } from '../storage';

/**
 * Check whether the user has a specific permission
 *
 * @param which Which permission we want to check against
 * @returns     Whether it's true
 */
export const hasPermission = (which: PermissionsChecklist): boolean => {
  return getPermissionsCurrent().includes(which);
};
