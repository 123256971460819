import { styled } from 'styled-components';

import { visuallyHidden } from '../../../styles/common';

export const FileUploadInput = styled.input`
  ${visuallyHidden}
`;

export const FileUploadLabel = styled.label`
  align-items: center;
  display: flex;
  width: 100%;
`;
