import React, { FC, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { useMutation, useQuery } from 'urql';

import { DEV_PANEL__TOGGLE } from '../../constants/keyboardShortcuts';
import {
  PushInfoDocument,
  PushUnsubscribeDocument,
} from '../../generated/graphql';
import useKeyboardShortcuts from '../../hooks/useKeyboardShortcuts';
import { getCanViewDevPanel } from '../../utils/permissions/devPanel';
import { getIsFirstLogin } from '../../utils/storage/firstLogin';

import mapDispatchToProps from './mapDispatchToProps';
import mapStateToProps from './mapStateToProps';
import { Props } from './props';

const DevPanel = lazy(() => import('../../components/DevPanel'));

/**
 * Container for dev panel, with various tools
 * to ease testing or debugging
 *
 * @param props Props passed to the component
 * @returns     The component itself
 */
const DevPanelContainer: FC<Props> = props => {
  const { panelState, requestTogglePanel, showInline } = props;

  const [{ data }, pushSubscriptionsRefresh] = useQuery({
    query: PushInfoDocument,
    variables: {},
  });
  const canViewDevPanel = getCanViewDevPanel();
  const [, pushUnsubscribe] = useMutation(PushUnsubscribeDocument);

  const isFirstLogin = getIsFirstLogin();

  useKeyboardShortcuts([
    {
      callback: requestTogglePanel,
      shortcut: DEV_PANEL__TOGGLE,
    },
  ]);

  if (
    (panelState === false && showInline === false) ||
    data === null ||
    data === undefined ||
    canViewDevPanel === false
  ) {
    return null;
  }

  const { createdAt, email, firstName, id, lastName } = data.me;

  const userInfo = {
    email,
    firstName,
    id,
    lastName,
  };

  return (
    <Suspense fallback={null}>
      <DevPanel
        dateFirstLogin={createdAt}
        isFirstLogin={isFirstLogin}
        pushSubscriptions={data.pushSubscriptions}
        pushSubscriptionsRefresh={pushSubscriptionsRefresh}
        pushUnsubscribe={pushUnsubscribe}
        userInfo={userInfo}
        {...props}
      />
    </Suspense>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DevPanelContainer);
