import { css, styled } from '@mui/material';

import devices from '../../../styles/devices';
import HeaderSegmentedButtons from '../SegmentedButtons';

/**
 * Calculated based on button width
 * (translate button + help button)
 */
const sideWidthMin = '70px';

export const HeaderContent = styled('div')`
  align-items: center;
  background-color: var(--color-background);
  border-bottom: var(--border-dark);
  display: grid;
  gap: var(--spacing-tiny);
  grid-template-columns: ${sideWidthMin} 1fr minmax(${sideWidthMin}, auto);
  padding: var(--spacing-small);

  @media ${devices.tabletS} {
    display: none;
  }
`;

const sideContent = css`
  align-items: center;
  display: flex;
`;

/**
 * Container for the Info icon
 */
export const RightElementContainer = styled('div')`
  justify-content: flex-end;
  ${sideContent}
`;

/**
 * Container for the Burger menu
 */
export const LeftElementContainer = styled('div')`
  justify-content: flex-start;
  ${sideContent}
`;

export const SubheaderWrapper = styled('div')({
  '&:empty': {
    display: 'none',
  },
  display: 'grid',
  gap: 'var(--spacing-medium)',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto auto auto',
  justifyContent: 'start',
  padding: '0 var(--spacing-tiny)',
});

export const SegmentedButtons = styled(HeaderSegmentedButtons)`
  grid-row: 2;
`;
