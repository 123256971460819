import { Button, styled } from '@mui/material';

import breakpoints from '../../../constants/breakpoints';
import { colorTertiary } from '../../../styles/mui/colors';

export const MuiButton = styled(Button)(({ theme }) => ({
  '& > .MuiButton-startIcon': {
    float: 'left',
  },
  '&.MuiButtonGroup-grouped': {
    [theme.breakpoints.up(breakpoints.tabletS)]: {
      width: 'var(--preference-segmented-button-width-desktop)',
    },
  },
  '&:focus:not(:focus-visible)': {
    backgroundColor: colorTertiary[500],
  },
  '&:hover,:active': {
    boxShadow: 'none',
  },
  border: 'var(--mui-button-outlined-border)',
  boxShadow: 'none',
  flex: '1 1 0',
  paddingLeft: 'var(--spacing-big)',
  paddingRight: 'var(--spacing-big)',
}));

export const SegmentedButtonLabel = styled('span')(() => ({
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 'var(--preference-button-line-clamp)',
  display: '-webkit-box',
  overflow: 'hidden',
  textAlign: 'start',
  textTransform: 'capitalize',
  textWrap: 'wrap',
}));
