/**
 * @file Helper functions for ElasticSearch queries
 */

import {
  Locale,
  Maybe,
  MessageSearchQuery,
  SearchQuery,
} from '../../../generated/graphql';
import {
  getDefaultLanguageCode,
  getLanguageCode,
} from '../../../utils/user/language';

/**
 * Get whether the localized title exists
 *
 * @param localized Localized value to check
 * @returns         Whether the value exists and is valid
 */
export const extractValue = (localized?: Maybe<string>): string | null => {
  if (localized === undefined || localized === null) {
    return null;
  }

  return localized.trim().length === 0 ? null : localized;
};

/**
 * Get locales by priority
 *
 * @returns Array of locales
 */
const getLocales = (): Locale[] => {
  return [
    // Try user language code first
    getLanguageCode(),

    // Try default language second
    getDefaultLanguageCode(),

    // Fallback to English
    Locale.en,

    // Fall back to any other language
    Locale.de,
    Locale.fr,
    Locale.it,
    Locale.nl,
  ];
};

/**
 * Extract title for a search query based on the user's locale
 *
 * @param title Title, as retrieved from the backend
 * @returns     The title to use
 */
export const extractTitle = (title?: SearchQuery['title']): string => {
  const locales = getLocales();

  if (title === undefined) {
    return '';
  }

  for (const locale of locales) {
    const localized = extractValue(title[locale]);

    if (localized !== null) {
      return localized;
    }
  }

  return '';
};

/**
 * Extract description for a search query based on the user's locale
 *
 * @param description Description, as retrieved from the backend
 * @returns           The description to use
 */
export const extractDescription = (
  description?: SearchQuery['description'],
): string | null => {
  const locales = getLocales();

  if (description === undefined || description === null) {
    return null;
  }

  for (const locale of locales) {
    const localized = extractValue(description[locale]);

    if (localized !== null) {
      return localized;
    }
  }

  return null;
};

/**
 * Get whether the query returned empty result
 *
 * @param data       Data returned by the MessageSearchQuery
 * @param isFetching Whether the data is fetching
 * @returns          True if empty, otherwise false
 */
export const getIsMessageSearchResultEmpty = (
  data: Pick<MessageSearchQuery, 'messageSearch'> | undefined | null,
  isFetching: boolean,
) => {
  if (isFetching === true) {
    return false;
  }

  // Data returned is different on production and development.
  // On production, it can happen that data.messageSearch exists and is null, and
  // typescript does not complain for it either
  return (
    data === null ||
    data?.messageSearch === null ||
    data?.messageSearch?.nodes?.length === 0
  );
};
