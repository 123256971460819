/**
 * @file Helpers for tasks cache
 */

import { Cache, FieldInfo } from '@urql/exchange-graphcache';

import {
  TasksDocument,
  TasksQuery,
  TasksQueryVariables,
} from '../../../../../../../generated/graphql';
import { MessageFeed } from '../../../../../../../models/message';
import {
  getIsTask,
  getTasksQueryOptions,
  insertTaskByDueDate,
} from '../../../../../../../utils/message/tasks';
import paginatedQueryDeletion from '../paginatedQueryDeletion';
import { removeNodeFromPaginatedQuery } from '../paginatedQueryHelpers';

export type TasksQueryFieldInfo = FieldInfo & {
  arguments: TasksQueryVariables;
};

/**
 * Check if the query with filters should be updated
 *
 * @param filters Filters on the query
 * @param message The message we want to add or that was updated
 * @returns       Whether the query with filters should be updated
 */
export const shouldUpdateFilteredTasks = (
  filters: TasksQueryVariables['filter'],
  message: MessageFeed,
) => {
  if (filters.status !== message.status) {
    return false;
  }

  const membersFilter = filters.members ?? [];

  if (membersFilter.length === 0) {
    return true;
  }

  const assignees = new Set(message.assignedMembers.map(({ id }) => id));
  return membersFilter.some(memberId => assignees.has(memberId));
};

/**
 * Adds or updates the task in the cache. If due date is removed, the message
 * is not a task anymore, so we remove it from kanban.
 *
 * @param message The message we want to add or that was updated
 * @param cache   Current GraphQL cache
 */
export const addOrUpdateTask = (message: MessageFeed, cache: Cache): void => {
  const isTask = getIsTask(message);

  const queries = cache.inspectFields('Query') as TasksQueryFieldInfo[];

  /**
   * Filter queries related to tasks with the same topic ID as the message
   */
  const tasksQueries = queries.filter(query => {
    return (
      query.fieldName === 'tasks' &&
      query.arguments?.filter.topicId === message.topic.id
    );
  });

  /**
   * The query data is stored in the cache for each filter combination, including different
   * combinations of status and members and we need to update all of them.
   */
  tasksQueries.forEach(query => {
    if (query.arguments?.filter === undefined) {
      reportError('Tasks cache query filter undefined');
      return;
    }

    const { members, status, topicId } = query.arguments.filter;

    cache.updateQuery<TasksQuery, TasksQueryVariables>(
      getTasksQueryOptions(topicId, status, members),
      dataTasks => {
        if (dataTasks === null) {
          return null;
        }

        const originalTasks = dataTasks.tasks.nodes as MessageFeed[];
        const filteredTasks = originalTasks.filter(
          messageItem => messageItem.id !== message.id,
        );

        const updatedTasks =
          isTask === true &&
          shouldUpdateFilteredTasks(query.arguments.filter, message)
            ? insertTaskByDueDate(message, filteredTasks)
            : filteredTasks;

        dataTasks.tasks.nodes = updatedTasks;

        if (updatedTasks.length < originalTasks.length) {
          dataTasks.tasks.nodesInfo.returned--;
          dataTasks.tasks.nodesInfo.total--;
        } else if (updatedTasks.length > originalTasks.length) {
          dataTasks.tasks.nodesInfo.returned++;
          dataTasks.tasks.nodesInfo.total++;
        }

        return dataTasks;
      },
    );
  });
};

/**
 * Removes task item from tasks cache
 *
 * @param taskId Id of the task we want to remove
 * @param cache  Current GraphQL cache
 */
export const removeTask = (taskId: MessageFeed['id'], cache: Cache): void => {
  paginatedQueryDeletion({
    cache,
    queryName: 'tasks',
    /**
     * Remove node from paginated query
     *
     * @param variables Variables used in the query
     */
    updateQuery: variables => {
      removeNodeFromPaginatedQuery({
        cache,
        nodeId: taskId,
        query: TasksDocument,
        queryName: 'tasks',
        variables,
      });
    },
  });
};
