import { ButtonProps } from '@mui/material';
import React, { ElementType } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import MuiIcon from '../MuiIcon';

import * as Styled from './styled';

type Props = {
  isActive: boolean;
};

/**
 * Segmented button mimicking MD3 design
 *
 * @param props          Props passed to the component
 * @param props.isActive Whether the button is active
 * @returns              The component itself
 */
const SegmentedButton = <C extends ElementType>(
  props: Props & ButtonProps<C, { component?: C }>,
) => {
  const { children, isActive, ...buttonProps } = props;
  return (
    <Styled.MuiButton
      color={isActive ? 'tertiary' : undefined}
      component={RouterLink}
      startIcon={isActive ? <MuiIcon icon="check" /> : undefined}
      variant={isActive ? 'contained' : 'outlined'}
      {...buttonProps}
    >
      <Styled.SegmentedButtonLabel>{children}</Styled.SegmentedButtonLabel>
    </Styled.MuiButton>
  );
};

export default SegmentedButton;
