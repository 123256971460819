/**
 * @file contains urql error exchange
 */

import { errorExchange, Exchange } from 'urql';

import { ApiError } from '../../constants/errors';

import { reportError } from '../../services/reporting';
import { addToastMessage } from '../../store/actions/toastMessage';
import store from '../../store/configureStore';
import {
  convertApiErrorToToast,
  extractApiErrors,
  extractOperationData,
  redirectToErrorRoute,
} from '../../utils/error';
import { errorCodeToRouteMap } from '../../utils/errorMaps';

/**
 * Handle errors that should lead to redirection to a new route
 *
 * @param apiError The error to handle
 */
const handleErrorScreen = (apiError: ApiError): void => {
  const errorRoute = errorCodeToRouteMap.get(apiError.code);

  if (errorRoute === undefined) {
    // Handle this unknown error route
    reportError('Route not found for code: ' + apiError.code);
    return;
  }

  redirectToErrorRoute(errorRoute);
};

/**
 * Handle errors that should lead to display a toast message to the user
 *
 * @param apiError The error to handle
 */
const handleErrorToast = (apiError: ApiError): void => {
  const toastMessage = convertApiErrorToToast(apiError.code);

  // Failed converting to toast data
  if (toastMessage === null) {
    // Handle this unknown error route
    reportError('Toast data not found for code: ' + apiError.code);
    return;
  }

  store.dispatch(addToastMessage(toastMessage));
};

/**
 * Error exchange that is used to catch all errors coming from the backend
 */
const exchange: Exchange = errorExchange({
  /**
   * Callback to handle onError event
   *
     @param error     A CombinedError that an incoming OperationResult contained
     @param operation The Operation of the incoming OperationResult
   */
  onError: (error, operation) => {
    /**
     * Catch possible backend errors here and send to bugsnag
     */
    reportError(error);

    const apiErrors = extractApiErrors(error);

    // Handle unknown error
    if (apiErrors === null) {
      // return reportError(error);
      extractOperationData(operation).forEach(definition => {
        reportError('Error performing: ' + definition);
      });
      return;
    }

    apiErrors.forEach(apiError => {
      if (apiError.type === 'screen') {
        handleErrorScreen(apiError);
      }

      if (apiError.type === 'toast') {
        handleErrorToast(apiError);
      }
    });
  },
});

export default exchange;
