/**
 * @file User settings mapper
 */
import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorUser } from './navigators';

const mapperUser = {
  // Users and roles
  [NotificationEvent.USER__ACTIVATED]: navigatorUser,
  [NotificationEvent.USER__DEACTIVATED]: navigatorUser,
  [NotificationEvent.USER__FIRST_LOG_IN]: navigatorUser,
  [NotificationEvent.USER__ROLE__UPDATED]: navigatorUser,
};

export default mapperUser;
