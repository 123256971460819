/**
 * @file Subscriptions' resolvers, updating
 */
import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  ActionType,
  TopicInfoSubscription,
  TopicInfoSubscriptionVariables,
} from '../../../../../generated/graphql';
import topicCreate from '../utils/updaters/topics/topicCreate';
import topicDelete from '../utils/updaters/topics/topicDelete';
import topicUpdateAll from '../utils/updaters/topics/topicUpdateAll';

const mapping: Record<
  ActionType,
  UpdateResolver<TopicInfoSubscription, TopicInfoSubscriptionVariables>
> = {
  [ActionType.CREATED]: topicCreate,
  [ActionType.DELETED]: topicDelete,
  [ActionType.UPDATED]: topicUpdateAll,
  [ActionType.ARCHIVED]: () => undefined,
  [ActionType.UNARCHIVED]: () => undefined,
};

/**
 * Handle subscription to everything related to topics
 *
 * @param result Result of a graphql operation
 * @param args   Arguments of a graphql operation
 * @param cache  URQL graphql cache
 * @param info   Resolver info
 */
const topicInfo: UpdateResolver<
  TopicInfoSubscription,
  TopicInfoSubscriptionVariables
> = (result, args, cache, info) => {
  const { action } = result.topicInfo;
  mapping[action](result, args, cache, info);
};

export default topicInfo;
