/**
 * @file Contains helper functions for message information
 */
import {
  ActionType,
  MessageInfoSubscription,
} from '../../../generated/graphql';

/**
 * Function that parses a message information from subscription
 *
 * @param data Subscription object containing information about message
 * @returns    Object containing parsed information about message
 */
export const getMessageInfo = (data: MessageInfoSubscription | undefined) => {
  const isActionCreated = data?.messageInfo.action === ActionType.CREATED;
  const isActionDeleted = data?.messageInfo.action === ActionType.DELETED;
  // ?.message needed because data.messageInfo is sometimes null
  // (even though TypeScript says it is not)
  const message = data?.messageInfo?.message;
  const messageInfoId = message?.id ?? null;
  const creator = message?.creator?.id ?? null;

  return {
    creator,
    isActionCreated,
    isActionDeleted,
    messageInfoId,
  };
};
