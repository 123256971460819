/**
 * @file Message details mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorMessage, navigatorTopicMain } from './navigators';

const mapperMessage = {
  // Message is archived or unarchived
  [NotificationEvent.MESSAGE__ARCHIVED]: navigatorMessage,
  [NotificationEvent.MESSAGE__ARCHIVED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.MESSAGE__ARCHIVED__FOR_CREATOR]: navigatorMessage,
  [NotificationEvent.MESSAGE__UNARCHIVED]: navigatorMessage,
  [NotificationEvent.MESSAGE__UNARCHIVED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.MESSAGE__UNARCHIVED__FOR_CREATOR]: navigatorMessage,

  // Message is created
  [NotificationEvent.MESSAGE__CREATED]: navigatorMessage,
  [NotificationEvent.MESSAGE__CREATED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.MESSAGE__EXTERNAL_CREATED]: navigatorMessage,

  // Message description is updated
  [NotificationEvent.MESSAGE__DESCRIPTION__UPDATED]: navigatorMessage,
  [NotificationEvent.MESSAGE__DESCRIPTION__UPDATED__FOR_ASSIGNEES]:
    navigatorMessage,
  [NotificationEvent.MESSAGE__DESCRIPTION__UPDATED__FOR_CREATOR]:
    navigatorMessage,

  // Message due date is updated or removed
  [NotificationEvent.MESSAGE__DUE_DATE__ADDED]: navigatorMessage,
  [NotificationEvent.MESSAGE__DUE_DATE__ADDED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.MESSAGE__DUE_DATE__ADDED__FOR_CREATOR]: navigatorMessage,
  [NotificationEvent.MESSAGE__DUE_DATE__UPDATED]: navigatorMessage,
  [NotificationEvent.MESSAGE__DUE_DATE__UPDATED__FOR_ASSIGNEES]:
    navigatorMessage,
  [NotificationEvent.MESSAGE__DUE_DATE__UPDATED__FOR_CREATOR]: navigatorMessage,
  [NotificationEvent.MESSAGE__DUE_DATE__REMOVED]: navigatorMessage,
  [NotificationEvent.MESSAGE__DUE_DATE__REMOVED__FOR_ASSIGNEES]:
    navigatorMessage,
  [NotificationEvent.MESSAGE__DUE_DATE__REMOVED__FOR_CREATOR]: navigatorMessage,

  // Message status is updated
  [NotificationEvent.MESSAGE__STATUS__UPDATED]: navigatorMessage,
  [NotificationEvent.MESSAGE__STATUS__UPDATED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.MESSAGE__STATUS__UPDATED__FOR_CREATOR]: navigatorMessage,

  // Message title is updated
  [NotificationEvent.MESSAGE__TITLE__UPDATED]: navigatorMessage,
  [NotificationEvent.MESSAGE__TITLE__UPDATED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.MESSAGE__TITLE__UPDATED__FOR_CREATOR]: navigatorMessage,

  // Label is assigned to or unassigned from a message
  [NotificationEvent.MESSAGE__LABEL__ADDED]: navigatorMessage,
  [NotificationEvent.MESSAGE__LABEL__ADDED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.MESSAGE__LABEL__ADDED__FOR_CREATOR]: navigatorMessage,
  [NotificationEvent.MESSAGE__LABEL__REMOVED]: navigatorMessage,
  [NotificationEvent.MESSAGE__LABEL__REMOVED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.MESSAGE__LABEL__REMOVED__FOR_CREATOR]: navigatorMessage,

  // User is assigned to or unassigned from a message
  [NotificationEvent.MESSAGE__ASSIGNEE__ADDED]: navigatorMessage,
  [NotificationEvent.MESSAGE__ASSIGNEE__ADDED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.MESSAGE__ASSIGNEE__ADDED__FOR_CREATOR]: navigatorMessage,
  [NotificationEvent.MESSAGE__ASSIGNEE__REMOVED]: navigatorMessage,
  [NotificationEvent.MESSAGE__ASSIGNEE__REMOVED__FOR_ASSIGNEES]:
    navigatorMessage,
  [NotificationEvent.MESSAGE__ASSIGNEE__REMOVED__FOR_CREATOR]: navigatorMessage,

  // User group is assigned to or unassigned from a message
  [NotificationEvent.MESSAGE__GROUP__ADDED]: navigatorMessage,
  [NotificationEvent.MESSAGE__GROUP__ADDED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.MESSAGE__GROUP__ADDED__FOR_CREATOR]: navigatorMessage,
  [NotificationEvent.MESSAGE__GROUP__REMOVED]: navigatorMessage,
  [NotificationEvent.MESSAGE__GROUP__REMOVED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.MESSAGE__GROUP__REMOVED__FOR_CREATOR]: navigatorMessage,

  // A file is added or deleted from a message
  [NotificationEvent.MESSAGE__FILE__ADDED]: navigatorMessage,
  [NotificationEvent.MESSAGE__FILE__ADDED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.MESSAGE__FILE__ADDED__FOR_CREATOR]: navigatorMessage,
  [NotificationEvent.MESSAGE__FILE__DELETED]: navigatorMessage,
  [NotificationEvent.MESSAGE__FILE__DELETED__FOR_ASSIGNEES]: navigatorMessage,
  [NotificationEvent.MESSAGE__FILE__DELETED__FOR_CREATOR]: navigatorMessage,

  // Message is deleted
  [NotificationEvent.MESSAGE__DELETED]: navigatorTopicMain,
  [NotificationEvent.MESSAGE__DELETED__FOR_ASSIGNEES]: navigatorTopicMain,
  [NotificationEvent.MESSAGE__DELETED__FOR_CREATOR]: navigatorMessage,
};

export default mapperMessage;
