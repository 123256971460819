/**
 * @file contains hook for handling native responses
 */
import { useDispatch } from 'react-redux';

import {
  EventAttachmentActionResponse,
  EventDeviceInfoResponse,
  EventFirebaseInfoResponse,
  MessageEventResponse,
} from '../../native-messaging/models/MessageEventResponse';
import {
  setNativeAppDeviceInfo,
  setNativeAppDownloadInfo,
  setNativeAppFirebaseInfo,
} from '../../store/actions/nativeApp';

import useNativePushSubscribe from './useNativePushSubscribe';

/**
 * Hook to handle responses from the native app
 *
 * @returns The response handler
 */
const useNativeResponseHandler = () => {
  const dispatch = useDispatch();
  const subscribeToPushNotifications = useNativePushSubscribe();

  const responseHandler: Record<
    MessageEventResponse['eventName'],
    (data: MessageEventResponse['data']) => void
  > = {
    /**
     * Dispatches the action to set native app download info
     *
     * @param data The data to set
     *
     * @returns    void
     */
    attachmentActionResponse: (data: EventAttachmentActionResponse['data']) =>
      dispatch(setNativeAppDownloadInfo(data)),
    /**
     * Dispatches the action to set native app device info
     *
     * @param data The data to set
     *
     * @returns    void
     */
    deviceInfoResponse: (data: EventDeviceInfoResponse['data']) =>
      dispatch(setNativeAppDeviceInfo(data)),
    /**
     * Dispatches the action to set native app Firebase info
     *
     * @param data The data to set
     *
     * @returns    void
     */
    firebaseInfoResponse: (data: EventFirebaseInfoResponse['data']) =>
      dispatch(setNativeAppFirebaseInfo(data)),
    /**
     * Subscribes to push notifications
     *
     * @returns void
     */
    notificationPermissionResponse: () => subscribeToPushNotifications(),
  };

  return responseHandler;
};

export default useNativeResponseHandler;
