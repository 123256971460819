/**
 * @file Redux mappers for composeMetaTitle container
 */

import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import AppState from '../../../models/state';
import { clearDraftRequest } from '../../../store/actions/draftsMain';
import { updateDraftTextRequest } from '../../../store/actions/draftsText';
import { getShouldDisableSubmit } from '../../compose/helpers';

import { DispatchProps, OwnProps, StateProps } from './types';

/**
 * Map Redux dispatch actions to component props
 *
 * @param dispatch          Redux dispatch function
 * @param ownProps          Props passed to the container
 * @param ownProps.nodeType The ID of the draft
 * @returns                 Mapped props
 */
export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  { nodeType },
) => ({
  /**
   * Dispatches request to clear text draft
   *
   * @returns void
   */
  requestDraftClear: () => dispatch(clearDraftRequest(nodeType)),
  /**
   * Dispatches request to update text draft
   *
   * @param text Text for the draft
   *
   * @returns    void
   */
  requestTextUpdate: text => dispatch(updateDraftTextRequest(nodeType, text)),
});

/**
 * Map Redux state to container props
 *
 * @param appState                     App state
 * @param appState.drafts              Drafts data
 * @param appState.draftsAttachments   Drafts' attachments data
 * @param ownProps                     Props passed to the container
 * @param ownProps.nodeType            Draft ID
 * @param ownProps.shouldDisableSubmit Whether the submit button should be disabled
 * @returns                            Mapped props
 */
export const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = (
  { drafts, draftsAttachments },
  { nodeType, shouldDisableSubmit = false },
) => {
  const attachments = draftsAttachments.get(nodeType);
  const draft = drafts.get(nodeType);

  return {
    nodeId: draft?.nodeId ?? null,
    shouldDisableSubmit: getShouldDisableSubmit(
      nodeType,
      draft,
      attachments,
      shouldDisableSubmit,
    ),
    text: draft?.text ?? '',
  };
};
