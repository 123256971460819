import React, { FC } from 'react';

import LoadingIndicator from '../MUI/LoadingIndicator';

import MuiIcon from '../MUI/MuiIcon';

import * as Styled from './styled';

type Props = {
  isRefreshing: boolean;
};

/**
 * Pull to refresh indicator component wrapping the loading indicator
 *
 * @param props              Props passed to the component
 * @param props.isRefreshing Whether the indicator should be shown
 * @returns                  The component itself
 */
const PullToRefreshIndicator: FC<Props> = ({ isRefreshing }) => {
  return (
    <Styled.Wrapper>
      {isRefreshing ? (
        <LoadingIndicator />
      ) : (
        <MuiIcon fontSize="medium" icon="refresh" />
      )}
    </Styled.Wrapper>
  );
};

export default PullToRefreshIndicator;
