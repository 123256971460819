/**
 * @file Redux mappers for composeMetaChecklist container
 */

import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import AppState from '../../../models/state';
import {
  addDraftChecklistItemRequest,
  removeDraftChecklistItemRequest,
  saveDraftChecklistRequest,
  updateReorderDraftChecklistRequest,
  updateStateDraftChecklistRequest,
  updateTextDraftChecklistRequest,
} from '../../../store/actions/draftsChecklists';

import { DispatchProps, OwnProps, StateProps } from './types';

/**
 * Map Redux action dispatch to component props
 *
 * @param dispatch          Redux action dispatcher
 * @param ownProps          Passed props
 * @param ownProps.nodeType The id of the draft
 * @returns                 Mapped state
 */
export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  { nodeType },
) => ({
  /**
   * Dispatches request to create a checklist item
   *
   * @param text Item text to add to the list
   */
  requestChecklistCreate: text => {
    dispatch(addDraftChecklistItemRequest(nodeType, text));
  },
  /**
   * Dispatches request to remove a checklist item
   *
   * @param position Position of the item to remove
   */
  requestChecklistDelete: position => {
    dispatch(removeDraftChecklistItemRequest(nodeType, position));
  },
  /**
   * Dispatches request to reorder a checklist items
   *
   * @param items Reordered items
   */
  requestChecklistReorder: items => {
    dispatch(updateReorderDraftChecklistRequest(nodeType, items));
  },
  /**
   * Dispatches request to save a checklist
   *
   * @returns Dispatched action
   */
  requestChecklistSave: () => dispatch(saveDraftChecklistRequest(nodeType)),
  /**
   * Dispatches request to set checklist item state
   *
   * @param position Position of the checklist item
   * @param state    Current state of the checklist item
   */
  requestChecklistSetState: (position, state) => {
    dispatch(updateStateDraftChecklistRequest(nodeType, position, state));
  },
  /**
   * Dispatches request to update checklist item text
   *
   * @param position Position of the checklist item
   * @param text     Content of the checklist item
   */
  requestChecklistSetText: (position, text) => {
    dispatch(updateTextDraftChecklistRequest(nodeType, position, text));
  },
});

/**
 * Map Redux state to container props
 *
 * @param appState          Current app state
 * @param appState.drafts   Drafts in Redux store
 * @param ownProps          Props passed directly
 * @param ownProps.nodeType Draft ID
 * @returns                 Mapped state
 */
export const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = ({ drafts }, { nodeType }) => ({
  dataChecklistItemsCurrent: drafts.get(nodeType)?.checklistCurrent ?? [],
  dataChecklistItemsSaved: drafts.get(nodeType)?.checklistSaved ?? [],
});
