/**
 * @file Parsing Messaging API form data
 */

import { DESCRIPTION, SUBTITLE } from '../../constants/conciergeFieldTypes';
import { Thread } from '../../generated/graphql';
import {
  ContactFormDataFieldFormatted,
  ContactFormDataFormatted,
  DataConciergeFieldRaw,
  DataConciergeID,
  DataConciergeRaw,
} from '../../models/message';

import { getLabelMessagingApi } from './label';
import getFieldType from './type';
import { getValueMessagingApi } from './value';

/**
 * Format concierge data retrieved via Messaging API
 *
 * @param dataString JSON string to parse
 * @param thread     Metadata for the thread related to this message
 * @returns          The formatted data
 */
export const formatConciergeData = (
  dataString: string,
  thread?: Thread | null,
): ContactFormDataFormatted | null => {
  if (thread === null || thread === undefined) {
    return null;
  }

  try {
    const dataRaw = JSON.parse(dataString) as DataConciergeRaw;
    const language = thread.submissionLanguage;

    if (dataRaw === null) {
      return null;
    }

    const fieldsAll: ContactFormDataFieldFormatted[] = Object.entries(
      dataRaw.fieldConfig,
    ).map(([id, field]: [DataConciergeID, DataConciergeFieldRaw]) => ({
      isOptional: true,
      label: getLabelMessagingApi(field, language),
      type: getFieldType(field.typeId),
      value: getValueMessagingApi(field, language, dataRaw.fieldValues[id]),
    }));

    /**
     * Filter out empty values,
     * except for Descriptions and Subtitles,
     * we show label key for them, the value is unimportant
     */
    const fields = fieldsAll.filter(({ value, type }) => {
      if ([DESCRIPTION, SUBTITLE].includes(type)) {
        return true;
      }

      return value !== null && value.trim().length !== 0;
    });

    return {
      description: null,
      fields,
      languages: {
        object: '',
        submitted: thread.submissionLanguage,
      },
      title: {
        [thread.submissionLanguage]: thread.title,
      },
    };
  } catch (_error) {
    return null;
  }
};
