import React, { FC, RefObject } from 'react';

import { PREFERENCE__SEARCH_RESULTS__TRIGGER } from '../../constants/pagination';
import useMessagesRead from '../../hooks/message/useMessagesRead';
import { MessageSearch } from '../../models/message';
import SkeletonFeedWrapper from '../Loading/FeedWrapper';

import SearchResultElement from './SearchResultElement';
import * as Styled from './styled';

type Props = {
  isLoading: boolean;
  messages: MessageSearch[] | undefined;
  requestLoadMore: (() => void) | null;
  scrollToIndex: number;
  scrollToRef: RefObject<HTMLLIElement>;
};

/**
 * Search results component
 *
 * @param props                 Props passed to the component
 * @param props.isLoading       Whether the data is being loaded
 * @param props.messages        Topic description shown in the header
 * @param props.requestLoadMore Request load more messages callback
 * @param props.scrollToIndex   Index of the message to scroll to
 * @param props.scrollToRef     Ref of the message to scroll to
 * @returns                     The component itself
 */
const SearchResults: FC<Props> = ({
  isLoading,
  messages = [],
  requestLoadMore,
  scrollToIndex,
  scrollToRef,
}) => {
  const marker = useMessagesRead();

  return (
    <SkeletonFeedWrapper
      isInitialLoad={false}
      shouldShowAbove={isLoading}
      shouldShowBelow={false}
    >
      <Styled.Ul>
        {messages.map((message, index) => {
          // Load more only if second to last element is visible and all results aren't loaded
          const shouldLoadMore =
            index === messages.length - PREFERENCE__SEARCH_RESULTS__TRIGGER;

          const shouldSetRef = scrollToIndex === index;

          return (
            <li key={message.id} ref={shouldSetRef ? scrollToRef : undefined}>
              <SearchResultElement
                marker={marker}
                message={message}
                requestLoadMore={shouldLoadMore ? requestLoadMore : null}
              />
            </li>
          );
        })}
      </Styled.Ul>
    </SkeletonFeedWrapper>
  );
};

export default SearchResults;
