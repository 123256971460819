import React, { FC, useEffect, useId, useState } from 'react';

import { DateOffsetInput, TimeUnit } from '../../generated/graphql';
import translate from '../../i18n/translate';
import { getOffsetEntries } from '../../utils/offset';
import Radio from '../Common/Radio';

import CustomOffset from './CustomOffset';

import * as Styled from './styled';

type Props = {
  dataOffsetCurrent: DateOffsetInput | null;
  requestOffsetRemove: () => void;
  requestOffsetSet: (offset: DateOffsetInput) => void;
};

const colorChecked = 'var(--color-primary-light)';
const colorUnchecked = 'var(--color-primary-lighter)';
const radioButtonSize = 19;

/**
 * TemplateOffset panel,
 * Consists of a radio list and custom offset input
 *
 * @param props                     Props passed to the component
 * @param props.dataOffsetCurrent   Current offset
 * @param props.requestOffsetRemove Request that the current offset is removed
 * @param props.requestOffsetSet    Request that the current offset is changed
 * @returns                         The component itself
 */
const TemplateOffset: FC<Props> = ({
  dataOffsetCurrent,
  requestOffsetRemove,
  requestOffsetSet,
}) => {
  const titleId = useId();
  const [isCustom, setIsCustom] = useState(false);
  const [customValue, setCustomValue] = useState<DateOffsetInput | null>(null);
  const entries = getOffsetEntries();

  /**
   * Preselect custom offset if currently saved offset
   * is not defined in offset entries.
   */
  useEffect(() => {
    if (
      dataOffsetCurrent &&
      (dataOffsetCurrent.unit !== TimeUnit.HOURS ||
        entries.some(entry => entry.value === dataOffsetCurrent?.amount) ===
          false)
    ) {
      setIsCustom(true);
      setCustomValue(dataOffsetCurrent);
    }
    // Only run this effect on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handle custom value change
   */
  useEffect(() => {
    if (!isCustom || customValue === null) {
      return;
    }

    if (customValue.amount === 0) {
      requestOffsetRemove();
    }

    requestOffsetSet(customValue);
  }, [customValue, isCustom, requestOffsetRemove, requestOffsetSet]);

  /**
   * Handle radio button group change
   *
   * @param isChecked Whether the radio button is checked
   * @param value     Radio button value
   */
  const handleChange = (isChecked: boolean, value: string) => {
    if (!isChecked) {
      return;
    }

    if (value === 'custom') {
      setIsCustom(true);
      return;
    }

    setIsCustom(false);

    if (value === '0') {
      requestOffsetRemove();
      return;
    }

    requestOffsetSet({ amount: parseInt(value, 10), unit: TimeUnit.HOURS });
  };

  /**
   * Check if radio button is checked
   *
   * @param value Radio button value
   * @returns     Whether the button is checked
   */
  const getIsChecked = (value: number | 'custom') => {
    if (value === 'custom') {
      return isCustom;
    }

    if (dataOffsetCurrent === null && value === 0) {
      return !isCustom;
    }

    return (
      !isCustom &&
      value === dataOffsetCurrent?.amount &&
      dataOffsetCurrent.unit === TimeUnit.HOURS
    );
  };

  /**
   * Handle current local value change
   *
   * @param offset Custom offset
   */
  const requestCustomValueChange = (offset: DateOffsetInput) => {
    if (offset.amount === 0) {
      setIsCustom(false);
      setCustomValue(null);
      requestOffsetRemove();
      return;
    }

    setCustomValue(offset);
    setIsCustom(true);
  };

  return (
    <Styled.Wrapper>
      <Styled.Title>{translate('MESSAGE__HEADING__OFFSET')}</Styled.Title>
      <Styled.RadioList aria-labelledby={titleId} role="radiogroup">
        {entries.map(entry => {
          return (
            <li key={`radio-list-item-${entry.value}`}>
              <Radio
                colorChecked={colorChecked}
                colorUnchecked={colorUnchecked}
                isChecked={getIsChecked(entry.value)}
                name={'Offset'}
                onChange={handleChange}
                size={radioButtonSize}
                value={entry.value}
              >
                {entry.label}
              </Radio>
            </li>
          );
        })}
        <li key="radio-list-item-custom">
          <Radio
            colorChecked={colorChecked}
            colorUnchecked={colorUnchecked}
            isChecked={isCustom}
            name="offset"
            onChange={handleChange}
            size={radioButtonSize}
            value="custom"
          >
            <CustomOffset
              isRequired={isCustom}
              offset={customValue}
              requestInputChange={requestCustomValueChange}
            />
          </Radio>
        </li>
      </Styled.RadioList>
    </Styled.Wrapper>
  );
};

export default TemplateOffset;
