/**
 * @file User group proxy links mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorUserGroup } from './navigators';

const mapperUserGroup = {
  [NotificationEvent.USER_GROUP__CREATED]: navigatorUserGroup,
  [NotificationEvent.USER_GROUP__DELETED]: navigatorUserGroup,
  [NotificationEvent.USER_GROUP__MEMBER__ADDED]: navigatorUserGroup,
  [NotificationEvent.USER_GROUP__MEMBER__REMOVED]: navigatorUserGroup,
  [NotificationEvent.USER_GROUP__TITLE__UPDATED]: navigatorUserGroup,
};

export default mapperUserGroup;
