/**
 * @file Handles keys for cache for some models that are not accounted for.
 */

import { KeyingConfig } from '@urql/exchange-graphcache';

import {
  ChecklistItem,
  DashboardKpi,
  I18nLocalization,
  I18nRole,
  I18nTargetLanguage,
  Message,
  Reminder,
  SearchQuery,
  UserNotifications,
} from '../../../../generated/graphql';
import { ReminderString } from '../../../../models/reminder';

/**
 * Handles keys for cache for some models that are not accounted for.
 */
const keys = {
  /**
   * Get extracted key for ChecklistItemTranslation
   *
   * @param translation      Translation for checklist trnaslation
   * @param translation.text Text of translated checklist item
   *
   * @returns                Text of translated checklist item
   */
  ChecklistItemTranslation: ({ text }: ChecklistItem['translation']) => text,
  /**
   * Get extracted key for ChecklistItemTranslation
   *
   * @returns null
   */
  CommentTranslation: (): null => null,
  /**
   * Get extracted key for DashboardKpi
   *
   * @param kpi                DashboardKpi object
   * @param kpi.searchQuery    Search query object
   * @param kpi.searchQuery.id Search query ID
   *
   * @returns                  Search query ID
   */
  DashboardKpi: ({ searchQuery: { id } }: DashboardKpi): SearchQuery['id'] => {
    return id;
  },
  /**
   * Get extracted key for DateOffset
   *
   * @returns null
   */
  DateOffset: (): null => null,
  /**
   * Get extracted key for GroupedNotifications
   *
   * @returns null
   */
  GroupedNotifications: (): null => null,
  /**
   * Get extracted key for HotelPermissions
   *
   * @returns null
   */
  HotelPermissions: (): null => null,
  /**
   * Get extracted key for I18n
   *
   * @returns null
   */
  I18n: (): null => null,
  /**
   * Get extracted key for I18nLocalization
   *
   * @param localization       I18nLocalization object
   * @param localization.value I18n value
   *
   * @returns                  I18n value
   */
  I18nLocalization: ({ value }: I18nLocalization): I18nLocalization['value'] =>
    value,
  /**
   * Get extracted key for I18nRole
   *
   * @param role       I18nRole object
   * @param role.value I18nRole value
   *
   * @returns          I18nRole value
   */
  I18nRole: ({ value }: I18nRole): I18nRole['value'] => value,
  /**
   * Get extracted key for I18nTargetLanguage
   *
   * @param targetLanguage       I18nTargetLanguage object
   * @param targetLanguage.value I18nTargetLanguage value
   *
   * @returns                    I18nTargetLanguage value
   */
  I18nTargetLanguage: ({
    value,
  }: I18nTargetLanguage): I18nTargetLanguage['value'] => value,
  /**
   * Get extracted key for MessageTranslation
   *
   * @param message    Message object
   * @param message.id Message ID
   *
   * @returns          Message ID
   */
  MessageTranslation: ({ id }: Message) => id,
  // NodesInfo returns the fetched count and total count of nodes.
  // Since the response structure is fixed and only returns counts,
  // there is no need to use keys to access specific fields within the response.
  /**
   * Get extracted key for NodesInfo
   *
   * @returns null
   */
  NodesInfo: (): null => null,
  /**
   * Get extracted key for PaginationResult
   *
   * @returns null
   */
  PaginationResult: (): null => null,
  // PaginationResultExtended returns the applied pagination params that are fixed,
  // and therefore we don't need to use keys
  /**
   * Get extracted key for PaginationResultExtended
   *
   * @returns null
   */
  PaginationResultExtended: (): null => null,
  /**
   * Get extracted key for Reminder
   *
   * @param reminder        Reminder object
   * @param reminder.amount Amount of reminder
   * @param reminder.unit   Unit of reminder
   *
   * @returns               Reminder string
   */
  Reminder: ({ amount, unit }: Reminder): ReminderString => `${amount}_${unit}`,
  /**
   * Get extracted key for SearchInfo
   *
   * @returns null
   */
  SearchInfo: (): null => null,
  /**
   * Get extracted key for SearchQueryTitle
   *
   * @returns null
   */
  SearchQueryTitle: (): null => null,
  /**
   * Get extracted key for SearchResult
   *
   * @returns null
   */
  SearchResult: (): null => null,
  /**
   * Get extracted key for UserNotifications
   *
   * @param notification      UserNotifications object
   * @param notification.type UserNotifications type
   *
   * @returns                 UserNotifications type
   */
  UserNotifications: ({ type }: UserNotifications): UserNotifications['type'] =>
    type,
  /**
   * For some reason TS doesn't recognize
   * UserNotifications as compatible type with Data (data type for arguments of this callback),
   * probably because they are missing properties like "id"/"_id"
   * so i manually defined this object as KeyingConfig
   */
} as KeyingConfig;

export default keys;
