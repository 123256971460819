/**
 * @file Redux mappers for composeMetaTopic container
 */

import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import AppState from '../../../models/state';
import {
  saveDraftTopicRequest,
  updateDraftTopicRequest,
} from '../../../store/actions/draftsMain';
import { searchDraftTemplateTopicRequest } from '../../../store/actions/draftsTemplateTopic';

import { DispatchProps, OwnProps, StateProps } from './types';

/**
 * Map Redux action dispatch to component props
 *
 * @param dispatch          Redux action dispatcher
 * @param ownProps          Passed props
 * @param ownProps.nodeType The id of the draft
 * @returns                 Mapped state
 */
export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  { nodeType },
) => ({
  /**
   * Dispatches request to set search draft for topic templates
   *
   * @param value Value to search for
   */
  requestTemplateTopicSearch: value => {
    dispatch(searchDraftTemplateTopicRequest(nodeType, value));
  },
  /**
   * Dispatches request to update draft for topic templates
   *
   * @param topicId The id of the topic
   */
  requestTopicChange: topicId => {
    dispatch(updateDraftTopicRequest(nodeType, topicId));
  },
  /**
   * Dispatches request to save topic draft
   *
   * @returns void
   */
  requestTopicSave: () => dispatch(saveDraftTopicRequest(nodeType)),
});

/**
 * Map Redux app state to component props
 *
 * @param appState          Current app state
 * @param appState.debug    Debug state of the app
 * @param appState.drafts   Drafts in the state
 * @param ownProps          Passed props
 * @param ownProps.nodeType The id of the draft
 * @returns                 Mapped state
 */
export const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = ({ debug, drafts }, { nodeType }) => {
  const draft = drafts.get(nodeType);
  return {
    dataTopicCurrent: draft?.templateTopicCurrent ?? '',
    dataTopicSaved: draft?.templateTopicSaved ?? '',
    dataTopicSearchValue: draft?.templateTopicSearch ?? '',
    debugLoading: debug.networkCompose,
    isEditMode: draft?.nodeId !== null,
  };
};
