/**
 * @file contains subscription exchange config for urql
 */
import { createClient as createWSClient } from 'graphql-ws';
import { Exchange, subscriptionExchange } from 'urql';

import { GRAPHQL_SUBSCRIPTIONS_URL } from '../../config';

import { getHotelIdFromUrl } from '../../utils/hotel';
import { getAuthToken } from '../../utils/storage/auth';

/**
 * The id of the timer keeping track of websocket connections
 * (we terminate those that are unresponsive)
 */
let timeoutId: number;

/**
 * The amount of time (in ms) to wait before considering a connection broken
 */
const timeoutDelay = 5000;

export const wsClient = createWSClient({
  /**
   * Callback function to get the connection params
   *
   * @returns Object containing the connection params
   */
  connectionParams: () => ({
    Authorization: `Bearer ${getAuthToken()}`,
    HotelId: getHotelIdFromUrl(),
  }),
  /**
   * Only connect after the first subscription is fired
   * This is needed because `hotelId` is not available in the url at start
   * (no hotelId in /).
   *
   * This can create a race condition
   * (if first sub happens while we are on /).
   *
   * @returns The params to use
   */
  lazy: true,

  /**
   * In some situations, the web socket connection is broken and stuck
   * This is especially pronounced on iOS, where switching apps
   * or locking the device will cause the app to be put in background,
   * and putting it back in focus doesn't work as expected,
   * as connections can't be resumed
   *
   * So, we're checking if the connection has been in such a state
   * for more than timeoutDelay, and if so, we're terminating it
   *
   * The termination is not permanent,
   * rather, the client tries to establish a new ws connection to the backend
   *
   * @see https://github.com/enisdenjo/graphql-ws/discussions/290
   */
  on: {
    /**
     * Callback function to handle ping event
     *
     * @param received Whether the connection received the ping
     */
    ping: received => {
      if (received === true) {
        return;
      }

      timeoutId = window.setTimeout(() => wsClient.terminate(), timeoutDelay);
    },
    /**
     * Callback function to handle pong event
     *
     * @param received Whether the connection received the pong
     */
    pong: received => {
      if (received) {
        clearTimeout(timeoutId);
      }
    },
  },

  /**
   * Try to always try reconnect to subscriptions
   *
   * @returns True
   */
  shouldRetry: () => true,

  url: GRAPHQL_SUBSCRIPTIONS_URL,
});

const exchange: Exchange = subscriptionExchange({
  /**
   * Callback function for forwarding subscription
   *
   * @param request A subscription Operation
   *
   * @returns       An ObservableLike object issuing ExecutionResults
   */
  forwardSubscription: request => ({
    /**
     * Callback for starting the Observable-like subscription
     *
     * @param sink An ObserverLike object with result, error, and completion callbacks
     *
     * @returns    A subscription handle providing an unsubscribe method to stop the subscription.
     */
    subscribe: sink => {
      const unsubscribe = wsClient.subscribe(
        { ...request, query: request.query ?? '' },
        sink,
      );
      return { unsubscribe };
    },
  }),
});

export default exchange;
