/**
 * @file Contains functions for various checks of user name
 */
import { UserStatus } from '../../generated/graphql';
import translate from '../../i18n/translate';
import { UserNameFields } from '../../models/user';

/**
 * Returns trimmed string if present
 * if null, empty or undefined, returns undefined
 *
 * @param name The name to check
 * @returns    undefined or trimmed string
 */
export const getNameChecked = (
  name: string | null | undefined,
): string | undefined => {
  if (name === undefined || name === null || name.trim().length === 0) {
    return;
  }

  return name.trim();
};

/**
 * Format users full name (extracted from userGetName to lower the complexity)
 *
 * @param firstName Checked first name (empty string if it doesn't exist)
 * @param lastName  Checked last name (empty string if it doesn't exist)
 * @returns         Formatted full name
 */
const getNameFormatted = (firstName = '', lastName = ''): string => {
  return `${firstName} ${lastName}`.trim();
};

/**
 * Get the name of the user by their status
 *
 * @param formattedName The formatted user name
 * @param status        The user's status
 * @returns             The text to use as the user's name
 */
const getNameByStatus = (
  formattedName: string,
  status: UserStatus = UserStatus.ACTIVE,
): string => {
  const textDeactivated = translate('USER__STATUS__USERNAME_DISABLED');
  const textDeleted = translate('USER__STATUS__USERNAME_DELETED');
  const textPending = translate('USER__STATUS__ACTIVATION_PENDING');

  const mapping: Record<UserStatus, () => string> = {
    /**
     * Get name with pending status
     *
     * @returns Formatted name with pending status
     */
    [UserStatus.ACTIVATION_PENDING]: () => `${formattedName} ${textPending}`,
    /**
     * Get name with active status
     *
     * @returns Formatted name with active status
     */
    [UserStatus.ACTIVE]: () => formattedName,
    /**
     * Get name with deactivated status
     *
     * @returns Formatted name with deactivated status
     */
    [UserStatus.DEACTIVATED]: () => `${formattedName} ${textDeactivated}`,
    /**
     * Get name with deleted status
     *
     * @returns Formatted name with deleted status
     */
    [UserStatus.DELETED]: () => textDeleted,
    /**
     * Get name with not assigned status
     *
     * @returns Formatted name with not assigned status
     */
    [UserStatus.NOT_ASSIGNED]: () => `${formattedName} ${textDeactivated}`,
  };

  return mapping[status]();
};

/**
 * Get a user's full name representation (or null if there's no data)
 *
 * @param user     User to get the name for
 * @param fallback The name to use as a fallback if it can't be extracted from user
 * @returns        The formatted name
 */
const userGetName = (
  user: UserNameFields,
  fallback = translate('FALLBACK__USERNAME'),
): string => {
  if (user === null) {
    return fallback;
  }

  const { firstName, lastName, status } = user;

  const firstNameChecked = getNameChecked(firstName);
  const lastNameChecked = getNameChecked(lastName);

  if (firstNameChecked === undefined && lastNameChecked === undefined) {
    return getNameByStatus(fallback, status);
  }

  const formattedName = getNameFormatted(firstNameChecked, lastNameChecked);
  return getNameByStatus(formattedName, status);
};

export default userGetName;
