/**
 * @file Optimistically update the cache, setting user status to be active
 */

import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  MutationUserActivateArgs,
  UserActivateMutation,
  UserStatus,
} from '../../../../../generated/graphql';

import { getSettingsUserById } from '../../updates/utils/getters/settings';

/**
 * Optimistically set user status to be active
 *
 * @param args  Arguments of a graphQL operation
 * @param cache URQL graphQL cache
 * @returns     Optimistic updates or null
 */
const userActivate: OptimisticMutationResolver<
  MutationUserActivateArgs,
  UserActivateMutation['userActivate'] | null
> = (args, cache) => {
  const user = getSettingsUserById(cache, args.id);

  if (user === null) {
    return null;
  }

  return {
    ...user,
    __typename: 'User',
    status: UserStatus.ACTIVE,
  };
};

export default userActivate;
