import { Placeholder } from '@tiptap/extension-placeholder';
import { StarterKit } from '@tiptap/starter-kit';
import React, { FC, useEffect, useRef } from 'react';

import { parseText } from '../../../formatters/editor';
import { useEditor } from '../../../hooks/useEditor';
import getPlaceholder from '../../../utils/placeholder';
import AttachmentList from '../../Attachments/AttachmentList';

import EditorControls from './EditorControls';
import { configurations } from './configurations';
import {
  getCustomLink,
  getEditorProps,
  getIsMaxLengthExceeded,
  shouldShowControls,
  validateTextareaMaxLength,
} from './helpers';
import * as Styled from './styled';
import { Props } from './types';

/**
 * tiptap Editor
 *
 * @param props                         Props passed to the component
 * @param props.breakpointMin           Breakpoint at which the component is hidden
 * @param props.className               styled-components generated class name, needed for styling
 * @param props.dataAttachments         Currently attached files
 * @param props.dataInputFileProps      Props passed to the InputFileAttachment component
 * @param props.id                      ID of the text area
 * @param props.isDisabled              Determines if the input is disabled
 * @param props.isRequired              Determines if the input is isRequired
 * @param props.maxLength               Maximum number of characters for the text area
 * @param props.minLength               Minimum text length
 * @param props.name                    name attribute for the textarea
 * @param props.nodeId                  The ID of the node being edited (null for creation)
 * @param props.onBlur                  Callback to be invoked when field is blurred
 * @param props.onChangeText            Callback to be invoked when text is changed
 * @param props.placeholder             Text area placeholder
 * @param props.requestAttachmentRemove Callback which removes attachment file
 * @param props.requestEditCancel       Callback which clears draft and exits edit mode
 * @param props.setIsEditorEmpty        Callback which checks is editor empty (if there are only empty spaces and new lines)
 * @param props.shouldDisableSubmit     If the submit button should be disabled
 * @param props.shouldFocus             Should the editor be auto focused
 * @param props.usage                   What the editor is used for
 * @param props.value                   Current text area value
 * @returns                             The component itself
 */
const Editor: FC<Props> = ({
  breakpointMin = null,
  className,
  dataAttachments = [],
  dataInputFileProps,
  id,
  isDisabled = false,
  isRequired = false,
  maxLength,
  minLength,
  name,
  nodeId = null,
  onBlur,
  onChangeText,
  placeholder,
  requestAttachmentRemove,
  requestEditCancel,
  setIsEditorEmpty,
  shouldDisableSubmit = false,
  shouldFocus,
  usage,
  value,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  /** We are using ref for 'usage' prop because of onUpdate() function */
  const usageRef = useRef(usage);

  const { buttons, multiline } = configurations[usage];
  const hasButtons = buttons.size !== 0;
  const showControls = shouldShowControls(hasButtons, isDisabled);

  const CustomLink = getCustomLink();
  const editor = useEditor({
    content: parseText(value),
    editable: isDisabled === false,
    editorProps: getEditorProps(
      isDisabled,
      isRequired,
      getPlaceholder(isRequired, placeholder),
      usage,
    ),
    extensions: [
      StarterKit,
      CustomLink,
      Placeholder.configure({
        placeholder: getPlaceholder(isRequired, placeholder),
      }),
    ],
    /**
     * Callback for update event on the editor
     *
     * @param params        Params passed to onUpdate function
     * @param params.editor Editor interface
     */
    onUpdate: ({ editor: editorInterface }) => {
      // editorInterface.isEmpty is true only when there's nothing entered
      // and the 2nd condition is for when the user types just whitespace
      // editorInterface.getText().trim() gives '' for '1. ' string,
      // because the editor is switching from paragraph to a list which has nothing in it
      // so we stopped using that
      const isEmpty =
        editorInterface.isEmpty ||
        editorInterface.getText().match(/^\s+$/) !== null;

      setIsEditorEmpty?.(isEmpty);

      let content: string;
      if (usageRef.current === 'commentExternal') {
        content = editorInterface.getText();
      } else {
        // If the user typed nothing, no need to send a JSON with no content
        content = isEmpty ? '' : JSON.stringify(editorInterface.getJSON());
      }

      onChangeText(content);

      /**
       * Validate programmatically,
       * because native maxLength attribute doesn't work with controlled components,
       * instead checkValidity always returns true, as the initial value was over the limit
       */
      validateTextareaMaxLength(textareaRef, content, usage);
      // Trigger native onChange event, so that any listeners above can catch it as if this was a regular textarea
      const event = new Event('input', { bubbles: true });
      textareaRef.current?.dispatchEvent(event);
    },
  });

  // Strip formatting when switching to guest tab (external comment)
  useEffect(() => {
    usageRef.current = usage;

    if (usage === 'commentExternal') {
      const content = editor?.getText() ?? '';

      editor?.commands.setContent(content);
      onChangeText(content);
    }
    /**
     * @todo Uncomment this
     *
     * It caused a problem on mobile, navigating
     * home ➞ topic ➞ post ➞ edit (title & description)
     * would focus the editor before the animation ended, making it jump
     */
    // editor.commands.focus('end');

    // Only trigger this effect when usage prop changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usage]);

  // Clear editor text when it's cleared in props
  useEffect(() => {
    if (value.length === 0) {
      editor?.commands.clearContent();
    }

    // editor?.comments in dependencies causes a memory leak
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // User switched to editing a different node
  useEffect(() => {
    // To prevent posting formatted text for external comments
    if (usage !== 'commentExternal') {
      editor?.commands.setContent(parseText(value));
    }
    // We DON'T want to apply this on every Redux value change
    // That messes up cursor positioning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor === null, nodeId]);

  if (editor === null) {
    return null;
  }

  /**
   * Bring focus to the editor (used when the styling tools are toggle on mobile)
   */
  const focusEditor = () => {
    editor.commands.focus('end');
  };

  if (multiline === false) {
    return (
      <Styled.Input
        id={id}
        isDisabled={isDisabled}
        isRequired={isRequired}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        onBlur={onBlur}
        onChange={onChangeText}
        shouldFocus={shouldFocus}
        value={value}
      />
    );
  }

  return (
    <Styled.Wrapper
      className={className}
      data-invalid={getIsMaxLengthExceeded(textareaRef.current)}
    >
      <Styled.Editor
        data-breakpoint-min={breakpointMin}
        data-editor-usage={usage}
        editor={editor}
        onBlur={onBlur}
      />
      {/* Plain textarea that's invisible for the users in order to support <label> click */}
      {/* and native form validation for required, minlength, maxlength */}
      <Styled.Textarea
        aria-hidden={true}
        autoFocus={shouldFocus}
        defaultValue={editor.isEmpty ? '' : value}
        disabled={isDisabled}
        id={id}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        onFocus={focusEditor}
        placeholder={getPlaceholder(isRequired, placeholder)}
        ref={textareaRef}
        required={isRequired}
        // Tabbing would trap focus on the editor, we want element that can be focused (by clicking on <label>)
        // but is not available for sequential focus via tab key
        tabIndex={-1}
      />
      {dataAttachments.length > 0 && (
        <AttachmentList
          attachments={dataAttachments}
          mode="compose"
          requestFileRemove={requestAttachmentRemove}
        />
      )}
      {showControls && (
        <EditorControls
          data-breakpoint-min={breakpointMin}
          editor={editor}
          inputFileProps={dataInputFileProps}
          isEditMode={nodeId !== null}
          onToggleTools={focusEditor}
          requestEditCancel={requestEditCancel}
          shouldDisableSubmit={shouldDisableSubmit}
          usage={usage}
        />
      )}
    </Styled.Wrapper>
  );
};

export default Editor;
