/**
 * @file Optimistically update the cache, adding unarchived message
 */

import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  MessageFullFragment,
  MessageFullFragmentDoc,
  MessagesDocument,
  MessageUnArchiveMutation,
  MutationMessageUnarchiveArgs,
  Node,
} from '../../../../../generated/graphql';
import paginatedQueryCreation from '../../updates/utils/updaters/paginatedQueryCreation';
import {
  addNodeToPaginatedQuery,
  filterQueries,
} from '../../updates/utils/updaters/paginatedQueryHelpers';

/**
 * Optimistically un-archives a message
 *
 * @param args  Arguments of a graphQL operation
 * @param cache URQL graphQL cache
 * @returns     Updated cache
 */
const messageUnarchive: OptimisticMutationResolver<
  MutationMessageUnarchiveArgs,
  MessageUnArchiveMutation['messageUnarchive'] | null
> = (args, cache) => {
  const message = cache.readFragment<MessageFullFragment, MessageFullFragment>(
    MessageFullFragmentDoc,
    {
      __typename: 'Message',
      id: args.id,
    },
  );

  if (message === null) {
    return null;
  }

  paginatedQueryCreation({
    cache,
    filterQueries: filterQueries({
      filterById: message.topic.id,
      parentIdName: 'topicId',
      queryName: 'messages',
    }),
    /**
     * Add node to paginated query
     *
     * @param variables Variables used in the query
     *
     * @returns         void
     */
    updateQuery: variables =>
      addNodeToPaginatedQuery({
        cache,
        node: { ...message, __typename: 'Message' } as Node,
        query: MessagesDocument,
        queryName: 'messages',
        variables,
      }),
  });

  return {
    ...message,
    __typename: 'Message',
  };
};

export default messageUnarchive;
