/**
 * @file contains the recurring reducer function
 */
import { RecurringAction, RecurringState } from '../../models/recurring';
import { RecurringActionMain } from '../../models/recurringMain';
import { RecurringActionRrule } from '../../models/recurringRrule';

import initialState from '../initialState';

import recurringMain, {
  actionTypes as RecurringMainActionTypes,
} from './recurringMain';
import recurringRrule, {
  actionTypes as RecurringRruleActionTypes,
} from './recurringRrule';

type Reducer = {
  reducer: (state: RecurringState, action: RecurringAction) => RecurringState;
  types: string[];
};

const reducers: Reducer[] = [
  {
    /**
     * Reducer for recurring main
     *
     * @param state  Recurring main state
     * @param action Recurring main action
     *
     * @returns      Recurring main state
     */
    reducer: (state: RecurringState, action: RecurringAction) => {
      return recurringMain(state, action as RecurringActionMain);
    },
    types: RecurringMainActionTypes,
  },
  {
    /**
     * Reducer for recurring rrule
     *
     * @param state  Recurring rrule state
     * @param action Recurring rrule action
     *
     * @returns      Recurring rrule state
     */
    reducer: (state: RecurringState, action: RecurringAction) => {
      return recurringRrule(state, action as RecurringActionRrule);
    },
    types: RecurringRruleActionTypes,
  },
];

/**
 * Drafts templates recurring reducer function
 *
 * @param state  Drafts state
 * @param action Drafts action
 * @returns      Drafts state
 */
const draftRecurring = (
  state = initialState.draftRecurring,
  action: RecurringAction,
): RecurringState => {
  const reducer = reducers.find(singleReducer =>
    singleReducer.types.includes(action.type),
  );

  if (reducer) {
    return reducer.reducer(state, action);
  }

  return state;
};

export default draftRecurring;
