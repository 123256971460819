import React, { FC, ReactNode } from 'react';

import { Locale } from '../../generated/graphql';
import translate from '../../i18n/translate';
import { TypeMessageFull } from '../../models/message';
import { getCanSeeReadBy } from '../../utils/permissions/message';
import { getLanguageCode } from '../../utils/user/language';

import MessageReadBy from './MessageReadBy';
import MessageSection from './MessageSection';
import * as Styled from './styled';

type Props = {
  message: TypeMessageFull;
};

/**
 * Information about who read the message
 *
 * @param props         Props passed to the component
 * @param props.message The message to show the info for
 * @returns             The component itself
 */
const MessageSectionReadBy: FC<Props> = ({ message }) => {
  if (getCanSeeReadBy() === false) {
    return null;
  }

  const { readBy, readPercentage } = message;

  const percentage =
    getLanguageCode() === Locale.de
      ? `${readPercentage} %`
      : `${readPercentage}%`;

  const heading: ReactNode = (
    <>
      {translate('MESSAGE__HEADING__READ_BY')}
      <Styled.Percentage>{percentage}</Styled.Percentage>
    </>
  );

  return (
    <MessageSection heading={heading}>
      <MessageReadBy percentage={readPercentage} readBy={readBy ?? []} />
    </MessageSection>
  );
};

export default MessageSectionReadBy;
