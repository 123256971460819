import React, { FC } from 'react';

import { Message } from '../../generated/graphql';

import { TranslationContainer } from '../../services/matomo/types';
import { getUserCurrent } from '../../utils/permissions/storage';

import ButtonTranslateMessage from './ButtonTranslateMessage';

export type Props = {
  className?: string;
  container: TranslationContainer;
  messageId: Message['id'];
};

/**
 * Translate button container
 *
 * @param props           Props passed to the component
 * @param props.className styled-components generated class name
 * @param props.container Container where the button is displayed
 * @param props.messageId ID of the message
 * @returns               The component itself
 */
const ButtonTranslateContainer: FC<Props> = ({
  className,
  container,
  messageId,
}) => {
  const user = getUserCurrent();

  /**
   * No need to show button if user does not have targetLanguage set
   */
  if (user?.targetLanguage === null || user?.targetLanguage === undefined) {
    return null;
  }

  return (
    <ButtonTranslateMessage
      className={className}
      container={container}
      messageId={messageId}
      targetLanguage={user.targetLanguage}
    />
  );
};

export default ButtonTranslateContainer;
