/**
 * @file contains function for sorting Reminders
 */

import { Reminder, TimeUnit } from '../../generated/graphql';

const reminderUnitMap: Record<TimeUnit, number> = {
  [TimeUnit.MINUTES]: 0,
  [TimeUnit.HOURS]: 1,
  [TimeUnit.DAYS]: 2,
};

/**
 * Sort array of Reminders (by unit and by amount)
 *
 * @param reminderA Reminders array item
 * @param reminderB Reminders array item
 * @returns         Number (Sort function sorting logic (< 0, 0, > 0))
 */
export const sortReminders = (
  reminderA: Reminder,
  reminderB: Reminder,
): number => {
  if (reminderA.unit !== reminderB.unit) {
    return reminderUnitMap[reminderA.unit] - reminderUnitMap[reminderB.unit];
  }

  return reminderA.amount - reminderB.amount;
};
