/**
 * @file Contains helpers for TaskDueDate component
 */

import { MessageStatus } from '../../../generated/graphql';

type DueDateColor = 'neutral' | 'error' | 'success';

export const inFutureColorsByStatus: Record<MessageStatus, DueDateColor> = {
  [MessageStatus.OPEN]: 'neutral',
  [MessageStatus.IN_PROGRESS]: 'neutral',
  [MessageStatus.RESOLVED]: 'success',
};

export const inPastColorsByStatus: Record<MessageStatus, DueDateColor> = {
  [MessageStatus.OPEN]: 'error',
  [MessageStatus.IN_PROGRESS]: 'error',
  [MessageStatus.RESOLVED]: 'success',
};

/**
 * Get the proper color for the due date chip component
 *
 * @param status  The Task status
 * @param dueDate The due date
 * @returns       Color prop value for Chip component
 */
export const getChipColor = (
  status: MessageStatus | null,
  dueDate: string | null,
): DueDateColor => {
  if (status === null || dueDate === null) {
    return 'neutral';
  }

  const isInPast = new Date(dueDate) <= new Date();
  const colorMap =
    isInPast === true ? inPastColorsByStatus : inFutureColorsByStatus;

  return colorMap[status];
};
