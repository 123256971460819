import React, { FC } from 'react';
import { connect, MapStateToProps } from 'react-redux';

import SearchResults from '../../components/SearchResults';
import SearchQueryInvalid from '../../components/SearchResults/SearchQueryInvalid';
import SearchResultsEmpty from '../../components/SearchResults/SearchResultsEmpty';
import useScrollRestore from '../../components/SearchResults/hooks/useScrollRestore';
import { UseESQueryReturn } from '../../hooks/elasticSearch/types';
import AppState from '../../models/state';
import { reportError } from '../../services/reporting';

type StateProps = {
  isLoading: boolean;
};

type OwnProps = {
  esQuery: UseESQueryReturn;
};

type Props = StateProps & OwnProps;

/**
 * Container for the Elastic search results, it takes a elastic search
 * query and transforms it into a string and makes a request for the results
 *
 * @param props           Props passed to the component
 * @param props.esQuery   Elastic search query
 * @param props.isLoading Whether the data is being fetched
 * @returns               SearchResult component
 */
const SearchResultsContainer: FC<Props> = ({ isLoading, esQuery }) => {
  const { error, isEmpty, loadMore, messages, searchTerm } = esQuery;
  const { scrollToIndex, scrollToRef } = useScrollRestore(messages.length);

  if (error) {
    reportError(error);
    return <SearchQueryInvalid />;
  }

  if (isEmpty) {
    return <SearchResultsEmpty searchTerm={searchTerm} />;
  }

  return (
    <SearchResults
      isLoading={isLoading}
      messages={messages}
      requestLoadMore={loadMore}
      scrollToIndex={scrollToIndex}
      scrollToRef={scrollToRef}
    />
  );
};

/**
 * Redux map state to props function
 *
 * @param state    Entire redux app state
 * @param ownProps Props passed to the component
 * @returns        Props for a react component derived from redux state
 */
const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps,
) => ({
  isLoading: ownProps.esQuery.isLoading || state.debug.networkMessages !== null,
});

export default connect(mapStateToProps)(SearchResultsContainer);
