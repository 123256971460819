/**
 * @file contains react hook for getting the specific search query
 */

import { BoolQuery, Sort } from 'elastic-ts';
import { CombinedError, useQuery } from 'urql';

import {
  SearchQueryDocument,
  SearchQueryQueryVariables,
} from '../../../generated/graphql';

import { extractDescription, extractTitle } from './helpers';

type Return = {
  description: string | null;
  error?: CombinedError;
  fetching: boolean;
  query: BoolQuery | null;
  sort: Sort | null;
  title: string;
};

/**
 * Gets a specific search query and parses it
 *
 * @param variables Params passed to the hook
 * @returns         Search query object
 */
const useGetSearchQuery = (variables: SearchQueryQueryVariables): Return => {
  const [{ data, error, fetching }] = useQuery({
    pause: variables.id === '',
    query: SearchQueryDocument,
    requestPolicy: 'network-only',
    variables,
  });

  let query: BoolQuery | null;
  let sort: Sort | null;

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    query = data !== undefined ? JSON.parse(data?.searchQuery.query) : null;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    sort = data?.searchQuery?.sort ? JSON.parse(data?.searchQuery?.sort) : null;
  } catch {
    query = null;
    sort = null;
  }

  return {
    description: extractDescription(data?.searchQuery.description),
    error,
    fetching,
    query,
    sort,
    title: extractTitle(data?.searchQuery.title),
  };
};

export default useGetSearchQuery;
