/**
 * @file Handle subscription to everything related to messages
 */

import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  ActionType,
  MessageInfoSubscription,
  MessageInfoSubscriptionVariables,
  MessagesDocument,
  Node,
} from '../../../../../generated/graphql';
import { addOrUpdateTask, removeTask } from '../utils/updaters/messages/tasks';
import paginatedQueryCreation from '../utils/updaters/paginatedQueryCreation';
import paginatedQueryDeletion from '../utils/updaters/paginatedQueryDeletion';
import {
  addNodeToPaginatedQuery,
  filterQueries,
  removeNodeFromPaginatedQuery,
} from '../utils/updaters/paginatedQueryHelpers';

const queryName = 'messages';

/**
 * Handle subscription to everything related to messages
 *
 * @param result                     Result of a graphql operation
 * @param result.messageInfo         Event data
 * @param result.messageInfo.action  The action that triggered the event
 * @param result.messageInfo.message The updated message
 * @param args                       Arguments of a graphql operation
 * @param args.topicId               The ID of the topic the message is in
 * @param cache                      URQL graphql cache
 */
const messageInfoSubscription: UpdateResolver<
  MessageInfoSubscription,
  MessageInfoSubscriptionVariables
> = ({ messageInfo: { action, message } }, { topicId }, cache) => {
  if (action === ActionType.CREATED || action === ActionType.UNARCHIVED) {
    paginatedQueryCreation({
      cache,
      filterQueries: filterQueries({
        filterById: topicId,
        parentIdName: 'topicId',
        queryName,
      }),
      /**
       * Add node to paginated query
       *
       * @param variables Variables used in the query
       */
      updateQuery: variables => {
        addNodeToPaginatedQuery({
          cache,
          node: message as Node,
          query: MessagesDocument,
          queryName,
          variables,
        });
      },
    });

    addOrUpdateTask(message, cache);

    return;
  }

  // Handle due date removal, update and, message status updates in the kanban queries
  if (action === ActionType.UPDATED) {
    addOrUpdateTask(message, cache);

    return;
  }

  if (action === ActionType.ARCHIVED || action === ActionType.DELETED) {
    paginatedQueryDeletion({
      cache,
      queryName,
      /**
       * Remove node from paginated query
       *
       * @param variables Variables used in the query
       */
      updateQuery: variables => {
        removeNodeFromPaginatedQuery({
          cache,
          nodeId: message.id,
          query: MessagesDocument,
          queryName,
          variables,
        });
      },
    });

    // Removes message from the tasks query cache
    removeTask(message.id, cache);

    return;
  }
};

export default messageInfoSubscription;
