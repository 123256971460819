/* eslint-disable complexity */
/**
 * @file Concierge value helper functions
 */

import {
  CHECKBOX,
  ConciergeFieldType,
  DATE,
  DATETIME,
  DESCRIPTION,
  DIVIDER,
  DROPDOWN,
  EMAIL,
  FEEDBACK_EMOJI,
  FEEDBACK_STARS,
  RADIOBOX_GROUP,
  RAW_RADIOBOX_GROUP,
  SIGNATURE,
  SUBMIT,
  SUBTITLE,
  TEXT,
  TEXT_AREA,
  TIME,
  TITLE,
} from '../../constants/conciergeFieldTypes';
import {
  ContactFormDataFieldRaw,
  DataConciergeFieldRaw,
  DataConciergeValue,
} from '../../models/message';
import {
  dateFormatUiFromString,
  dateTimeFormatUiFromString,
  timeFormatUiFromString,
} from '../../utils/date/format';

import { getValueLocalized } from './record';

import getFieldType from './type';

/**
 * Callback to extract a value from a field
 *
 * @param field              The field to get the value for
 * @param submissionLanguage The language the form was submitted in
 * @param valueRaw           The value to parse
 * @returns                  Value in format used on the frontend
 */
type ValueExtractor = (
  field: DataConciergeFieldRaw,
  submissionLanguage: string,
  valueRaw: DataConciergeValue,
) => string;

/**
 * Get the label to show in <th> for the concierge table
 *
 * @param field Raw field data
 * @returns     The value to use
 */
export const getValue = (field: ContactFormDataFieldRaw): string => {
  const type = parseInt(field.type, 10);
  const { value } = field;

  if (type === DATE) {
    return dateFormatUiFromString(value);
  }

  if (type === DATETIME) {
    return dateTimeFormatUiFromString(value);
  }

  return value;
};

/**
 * Extract numeric value from raw data
 *
 * @param valueRaw Raw field data
 * @param min      Minimal allowed value
 * @param max      Maximal allowed value
 * @returns        The value to use
 */
const getValueNumeric = (
  valueRaw: DataConciergeValue,
  min: number,
  max: number,
): string => {
  /**
   * Constrain the passed number to min and max values
   *
   * @param target The number to parse
   * @returns      The number to use
   */
  const getNumberBounded = (target: number): number => {
    return Math.max(Math.min(target, max), min);
  };

  if (typeof valueRaw === 'number') {
    return getNumberBounded(valueRaw).toString();
  }

  if (typeof valueRaw === 'string') {
    return getNumberBounded(parseInt(valueRaw, 10)).toString();
  }

  return min.toString();
};

/**
 * Get feedback emoji field value
 *
 * @param _field    The raw field data
 * @param _language The field language
 * @param valueRaw  Field raw value
 * @returns         The value to use
 */
const getValueEmoji: ValueExtractor = (_field, _language, valueRaw) => {
  return getValueNumeric(valueRaw, 0, 2);
};

/**
 * Get feedback stars field value
 *
 * @param _field    The raw field data
 * @param _language The field language
 * @param valueRaw  Field raw value
 * @returns         The value to use
 */
const getValueStars: ValueExtractor = (_field, _language, valueRaw) => {
  return getValueNumeric(valueRaw, 0, 5);
};

/**
 * Get boolean field value
 *
 * @param _field    The raw field data
 * @param _language The field language
 * @param valueRaw  Field raw value
 * @returns         The value to use
 */
const getValueBoolean: ValueExtractor = (_field, _language, valueRaw) => {
  return valueRaw === true || valueRaw === '1' ? '1' : '0';
};

/**
 * Get date field value
 *
 * @param _field    The raw field data
 * @param _language The field language
 * @param valueRaw  Field raw value
 * @returns         The value to use
 */
const getValueDate: ValueExtractor = (_field, _language, valueRaw) => {
  if (typeof valueRaw === 'string') {
    return dateFormatUiFromString(valueRaw);
  }

  return '';
};

/**
 * Get date time field value
 *
 * @param _field    The raw field data
 * @param _language The field language
 * @param valueRaw  Field raw value
 * @returns         The value to use
 */
const getValueDateTime: ValueExtractor = (_field, _language, valueRaw) => {
  if (typeof valueRaw === 'string') {
    return dateTimeFormatUiFromString(valueRaw);
  }

  return '';
};

/**
 * Get field value coming via extra key
 *
 * @param field    The raw field data
 * @param language The field language
 * @param valueRaw Field raw value
 * @returns        The value to use
 */
const getValueExtra: ValueExtractor = (field, language, valueRaw): string => {
  if (valueRaw === null || typeof valueRaw === 'boolean') {
    return '';
  }

  const index = parseInt(valueRaw.toString(), 10);

  if (Number.isNaN(index)) {
    return '';
  }

  try {
    let options: string[] = [];

    if (field.typeId.toString() === RAW_RADIOBOX_GROUP) {
      const value = getValueLocalized(field.extra, language) ?? '';
      const optionsRaw = JSON.parse(value) as unknown;

      if (Array.isArray(optionsRaw)) {
        // Will always be string[], this is just to appease TypeScript
        options = optionsRaw.map(option =>
          typeof option === 'string' ? option : '',
        );
      }
    }

    if (index < 0 || options.length <= index) {
      return '';
    }

    return options[index];
  } catch (_error) {
    return '';
  }
};

/**
 * Get regular text field value
 *
 * @param _field    The raw field data
 * @param _language The field language
 * @param valueRaw  Field raw value
 * @returns         The value to use
 */
const getValueTextual: ValueExtractor = (_field, _language, valueRaw) => {
  if (typeof valueRaw === 'string') {
    return valueRaw;
  }

  return '';
};

/**
 * Get time field value
 *
 * @param _field    The raw field data
 * @param _language The field language
 * @param valueRaw  Field raw value
 * @returns         The value to use
 */
const getValueTime: ValueExtractor = (_field, _language, valueRaw) => {
  if (typeof valueRaw === 'string') {
    return timeFormatUiFromString(valueRaw);
  }

  return '';
};

/**
 * Get a value to use on the frontend
 *
 * @param field    The raw field data
 * @param language The field language
 * @param valueRaw Field raw value
 * @returns        The value to use
 */
export const getValueMessagingApi: ValueExtractor = (
  field,
  language,
  valueRaw,
) => {
  const mapping: Record<ConciergeFieldType, ValueExtractor> = {
    [CHECKBOX]: getValueBoolean,
    [DATE]: getValueDate,
    [DATETIME]: getValueDateTime,
    [DESCRIPTION]: getValueTextual,
    [DIVIDER]: getValueTextual,
    [DROPDOWN]: getValueTextual,
    [EMAIL]: getValueTextual,
    [FEEDBACK_EMOJI]: getValueEmoji,
    [FEEDBACK_STARS]: getValueStars,
    [RADIOBOX_GROUP]: getValueExtra,
    [SIGNATURE]: getValueTextual,
    [SUBMIT]: getValueTextual,
    [SUBTITLE]: getValueTextual,
    [TEXT]: getValueTextual,
    [TEXT_AREA]: getValueTextual,
    [TIME]: getValueTime,
    [TITLE]: getValueTextual,
  };

  return mapping[getFieldType(field.typeId)](field, language, valueRaw);
};
