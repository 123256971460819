import React, { FC, ReactNode, useId } from 'react';
import { LinkProps } from 'react-router-dom';

import { Reminder } from '../../generated/graphql';
import translate from '../../i18n/translate';
import { dateTimeFormatUi } from '../../utils/date/format';
import MessageDueDateForm from '../Forms/MessageDueDateForm';
import MetaContainerInline, {
  Props as PropsInline,
} from '../MetaContainerInline';
import MetaContainerPopup, { Props as PropsPopup } from '../MetaContainerPopup';

import { getIsSaveDisabled } from './helpers';

type Props = {
  className?: string;
  dataDateDueCurrent: Date | null;
  dataDateDueSaved: Date | null;
  dataDateDueMin?: Date | null;
  dataRemindersCurrent: Reminder[];
  dataRemindersSaved: Reminder[];
  inputCustom?: ReactNode;
  isActive: boolean;
  linkToggleProps?: LinkProps;
  onClose?: () => void;
  requestDateDueRemove?: () => void;
  requestDateDueSet: (date: Date) => void;
  requestRemindersAdd: (reminder: Reminder) => void;
  requestRemindersRemove: (position?: number) => void;
  requestRemindersSet: (position: number, reminder: Reminder) => void;
  requestSave: () => void;
};

/**
 * Date due popup for the compose block
 *
 * @param props                        Props passed to the component
 * @param props.className              styled-components generated class name, needed for styling
 * @param props.dataDateDueCurrent     Current due date
 * @param props.dataDateDueMin         Minimum date
 * @param props.dataDateDueSaved       Saved due date
 * @param props.dataRemindersCurrent   Current reminders
 * @param props.dataRemindersSaved     Saved reminders
 * @param props.inputCustom            If we want a custom component instead of plain input
 * @param props.isActive               Whether the component should be visible
 * @param props.linkToggleProps        Props for the toggle link
 * @param props.onClose                Callback to be invoked when the user clicks close button
 * @param props.requestDateDueRemove   Request that the current due date is removed (set to null)
 * @param props.requestDateDueSet      Callback to be invoked when a due date is set
 * @param props.requestRemindersAdd    Request that the new reminder is added
 * @param props.requestRemindersRemove Request that the current reminder is removed (or all of the current reminders are removed)
 * @param props.requestRemindersSet    Request that the reminder is changed
 * @param props.requestSave            Request that the current due date and current reminders are saved
 * @returns                            The component itself
 */
const MetaDateDue: FC<Props> = ({
  className,
  dataDateDueCurrent,
  dataDateDueMin = null,
  dataDateDueSaved,
  dataRemindersCurrent,
  dataRemindersSaved,
  inputCustom,
  isActive,
  linkToggleProps = null,
  onClose,
  requestDateDueRemove = null,
  requestDateDueSet,
  requestRemindersAdd,
  requestRemindersRemove,
  requestRemindersSet,
  requestSave,
}) => {
  /**
   * We need to check if currently selected date is equal to draft/saved date
   * so we can disable save button if they are equal.
   */
  const isSaveDisabled = getIsSaveDisabled(
    dataDateDueCurrent,
    dataDateDueSaved,
    dataRemindersCurrent,
    dataRemindersSaved,
  );

  const idForm = useId();
  const idHeading = useId();
  const idSubheading = useId();

  // Popup top buttons labels
  const labelAction = translate('GENERAL__REMOVE');
  const labelActionColor = 'var(--color-button-danger)';
  const labelConfirm = translate('GENERAL__SAVE');
  const labelClose = translate('GENERAL__CLOSE');

  const textPlaceholder = translate('DUE_DATE__PLACEHOLDER');
  const textHeading = textPlaceholder;

  const valueSaved =
    dataDateDueSaved === null ? '' : dateTimeFormatUi(dataDateDueSaved);

  // Props used for both inline and popup meta
  const propsCommon: PropsInline | PropsPopup = {
    action:
      requestDateDueRemove !== null && dataDateDueSaved !== null
        ? {
            color: labelActionColor,
            label: labelAction,
            /**
             * On click event handler
             */
            onClick: () => {
              requestDateDueRemove();
              requestRemindersRemove();
            },
          }
        : null,
    className,
    idForm,
    idHeading,
    isSaveDisabled,
    labelClose,
    labelConfirm,
    onClose,
    textHeading,
  };

  const children = (
    <MessageDueDateForm
      ariaDescribedBy={idSubheading}
      ariaLabelledBy={idHeading}
      dataDateDueCurrent={dataDateDueCurrent}
      dataDateDueMin={dataDateDueMin}
      dataRemindersCurrent={dataRemindersCurrent}
      idForm={idForm}
      requestDateDueSet={requestDateDueSet}
      requestRemindersAdd={requestRemindersAdd}
      requestRemindersRemove={requestRemindersRemove}
      requestRemindersSet={requestRemindersSet}
      requestSave={requestSave}
    />
  );

  if (linkToggleProps === null) {
    return (
      <MetaContainerInline {...propsCommon}>{children}</MetaContainerInline>
    );
  }

  return (
    <MetaContainerPopup
      {...propsCommon}
      inputCustom={inputCustom}
      linkProps={linkToggleProps}
      placeholder={textPlaceholder}
      shouldResize={false}
      shouldShowPopup={isActive}
      value={valueSaved}
    >
      {children}
    </MetaContainerPopup>
  );
};

export default MetaDateDue;
