/**
 * @file contains helper functions for Editor
 */

import { Link, LinkOptions } from '@tiptap/extension-link';
import { Mark, markInputRule } from '@tiptap/react';
import { EditorProps } from 'prosemirror-view';

import translate from '../../../i18n/translate';

import { EditorUsage } from './types';

/**
 * Editor usages that require stripping down formatting
 */
export const formattingUsages = new Set<EditorUsage>([
  'commentExternal',
  'message',
  'messageEdit',
  'template',
  'templateMobile',
]);

/**
 * Generate props for the editor
 *
 * @param isDisabled  Whether the editor is disabled
 * @param isRequired  Whether the data is isRequired to be entered
 * @param placeholder The placeholder text to add
 * @param usage       What the editor is used for
 * @returns           The attributes to add to the editor
 */
export const getEditorProps = (
  isDisabled: boolean,
  isRequired: boolean,
  placeholder = '',
  usage: EditorUsage,
): EditorProps => ({
  attributes: {
    'aria-label': translate('GENERAL__DESCRIPTION'),
    'aria-multiline': 'true',
    'aria-placeholder': placeholder,
    'aria-readonly': isDisabled ? 'true' : 'false',
    'aria-required': isRequired ? 'true' : 'false',
    role: 'textbox',
  },
  /**
   * If comment is external strip down formatting
   *
   * @param html The pasted html
   * @returns    HTML or plain text as string
   */
  transformPastedHTML(html) {
    if (usage === 'commentExternal') {
      const el = document.createElement('div');
      el.innerHTML = html;
      return el.textContent ?? el.innerText;
    }

    return html;
  },
  /**
   * If text is copied html strip down formatting for security reasons
   *
   * @param text The pasted text
   * @returns    Text as string
   */
  transformPastedText(text) {
    if (formattingUsages.has(usage)) {
      const el = document.createElement('div');
      el.innerHTML = text;
      return el.textContent ?? el.innerText;
    }

    return text;
  },
});

/**
 * tiptap/extension-link doesn't support detecting links on input,
 * so this adds that functionality
 *
 * @returns Our Custom link that supports links on input
 */
export const getCustomLink = (): Mark<LinkOptions, string> => {
  const linkRegex =
    /(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,}\b(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)\s$/gi;

  return Link.extend({
    /**
     * Adds input rules to the editor
     *
     * @returns Array of input rules
     */
    addInputRules() {
      return [
        markInputRule({
          find: linkRegex,
          getAttributes: (match: string[]) => ({
            href: match[0].includes('http')
              ? match[0].trim().replace(/\.+$/, '')
              : `https://${match[0].trim().replace(/\.+$/, '')}`,
          }),
          type: this.type,
        }),
      ];
    },
  });
};

/**
 * Whether we should show EditorControls
 * (Extracted to lower the complexity)
 *
 * @param hasButtons Whether the Editor contains buttons
 * @param isDisabled Whether the Editor is disabled
 * @returns          Whether to show EditorControls
 */
export const shouldShowControls = (
  hasButtons: boolean,
  isDisabled: boolean,
) => {
  return hasButtons && isDisabled === false;
};

/**
 * Check whether the maxLength attribute is exceeded if set.
 *
 * @param textareaElement Textarea element
 * @returns               Whether maxLength is exceeded
 */
export const getIsMaxLengthExceeded = (
  textareaElement: HTMLTextAreaElement | null,
) => {
  const { maxLength = -1, value = '' } = textareaElement ?? {};

  return maxLength > -1 && value.length > maxLength;
};

/**
 * Error message translation keys for each usage
 *
 */
const maxLengthExceededTranslations: Record<EditorUsage, string | null> = {
  comment: 'COMMENTS__ERROR__TEXT_SIZE',
  commentExternal: 'COMMENTS__ERROR__TEXT_SIZE',
  message: 'MESSAGE__ERROR__TEXT_SIZE',
  messageEdit: 'MESSAGE__ERROR__TEXT_SIZE',
  template: 'MESSAGE__ERROR__TEXT_SIZE',
  templateMobile: 'MESSAGE__ERROR__TEXT_SIZE',
  topic: null,
};

/**
 * Get the correct error message when maxLength is exceeded
 *
 * @param usage Editor usage prop
 * @returns     The error message
 */
export const getMaxLengthErrorMessage = (usage: EditorUsage) => {
  const translationKey = maxLengthExceededTranslations[usage];

  return translationKey ? translate(translationKey) : null;
};

/**
 * Validates textarea maxLength attribute programmatically.
 * It does not show system validation error message if the value
 * is changed indirectly, so we need to validate this way.
 *
 * @param textareaRef Textarea Ref Object
 * @param jsonValue   The next value that will be set
 * @param usage       Editor usage prop if provided
 */
export const validateTextareaMaxLength = (
  textareaRef: React.RefObject<HTMLTextAreaElement | null>,
  jsonValue: string,
  usage: EditorUsage,
) => {
  const { current: textareaElement } = textareaRef;

  if (!textareaElement || textareaElement.maxLength === -1) {
    return;
  }

  const { maxLength } = textareaElement;

  // Can't use isMaxLengthExceeded at this point because the new text is not in the editor yet
  const errorMessage =
    jsonValue.length > maxLength ? getMaxLengthErrorMessage(usage) : null;

  if (errorMessage) {
    textareaElement.setCustomValidity(errorMessage);
    textareaElement.reportValidity();
  } else {
    textareaElement.setCustomValidity('');
  }
};
