/**
 * @file Redux mappers for composeMetaTitle container
 */

import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import AppState from '../../../models/state';
import {
  saveDraftAssigneeRequest,
  searchDraftAssigneesRequest,
  updateDraftAssigneeGroupRequest,
  updateDraftAssigneeMemberRequest,
} from '../../../store/actions/draftsAssignees';

import { DispatchProps, OwnProps, StateProps } from './types';

/**
 * Map Redux dispatch actions to component props
 *
 * @param dispatch          Redux dispatch function
 * @param ownProps          Props passed to the container
 * @param ownProps.nodeType The ID of the draft
 * @returns                 Mapped props
 */
export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  { nodeType },
) => ({
  /**
   * Dispatches request to update assignee group draft
   *
   * @param groupId Id of the group to update
   * @param state   Current value of the state
   */
  requestAssignGroupUpdate: (groupId, state) => {
    dispatch(updateDraftAssigneeGroupRequest(nodeType, groupId, state));
  },
  /**
   * Dispatches request to update assignee member daft
   *
   * @param groupId Id of the group to update
   * @param state   Current value of the state
   */
  requestAssignMemberUpdate: (groupId, state) => {
    dispatch(updateDraftAssigneeMemberRequest(nodeType, groupId, state));
  },
  /**
   * Dispatches request to save assignees draft
   *
   * @returns void
   */
  requestAssignSave: () => dispatch(saveDraftAssigneeRequest(nodeType)),
  /**
   * Dispatches request for searching assignees draft
   *
   * @param value String to search for
   */
  requestAssigneeSearch: value => {
    dispatch(searchDraftAssigneesRequest(nodeType, value));
  },
});

/**
 * Map Redux state to container props
 *
 * @param appState          App state
 * @param appState.debug    Debug state of the app
 * @param appState.drafts   Drafts data
 * @param ownProps          Props passed to the container
 * @param ownProps.nodeType Draft ID
 * @param ownProps.topicId  Topic ID (used for messages only)
 * @returns                 Mapped props
 */
export const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = ({ debug, drafts }, { nodeType, topicId }) => {
  const draft = drafts.get(nodeType);

  if (draft === undefined) {
    return {
      dataAssigneeSearchValue: '',
      dataGroupsAssigned: new Set(),
      dataGroupsSaved: new Set(),
      dataMembersAssigned: new Set(),
      dataMembersSaved: new Set(),
      debugLoading: debug.networkCompose,
      topicId,
    };
  }

  return {
    dataAssigneeSearchValue: draft.assigneeSearch,
    dataGroupsAssigned: draft.assigneeGroupsCurrent,
    dataGroupsSaved: draft.assigneeGroupsSaved,
    dataMembersAssigned: draft.assigneeMembersCurrent,
    dataMembersSaved: draft.assigneeMembersSaved,
    debugLoading: debug.networkCompose,
    topicId: nodeType === 'MESSAGE' ? topicId : draft.templateTopicSaved,
  };
};
