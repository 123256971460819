/**
 * @file Redux mappers for composeMetaTemplate container
 */

import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import AppState from '../../../models/state';
import { populateDraftRequest } from '../../../store/actions/draftsMain';
import {
  resetDraftTemplateRequest,
  saveDraftTemplateRequest,
  searchDraftTemplatesRequest,
  setDraftTemplateRequest,
} from '../../../store/actions/draftsTemplate';

import { DispatchProps, OwnProps, StateProps } from './types';

/**
 * Map Redux action dispatch to component props
 *
 * @param dispatch          Redux action dispatcher
 * @param ownProps          Passed props
 * @param ownProps.nodeType The id of the draft
 * @returns                 Mapped state
 */
export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  { nodeType },
) => ({
  /**
   * Dispatches request to search for templates
   *
   * @param term Term to search for
   */
  onTemplatesSearch: term => {
    dispatch(searchDraftTemplatesRequest(nodeType, term));
  },
  /**
   * Dispatches request to populate message template
   *
   * @param template Template to be populated with
   */
  requestDraftPopulate: template => {
    dispatch(populateDraftRequest('MESSAGE', template));
  },
  /**
   * Dispatches request to remove template draft
   *
   * @returns void
   */
  requestTemplateRemove: () => dispatch(resetDraftTemplateRequest(nodeType)),
  /**
   * Dispatches request to save template draft
   *
   * @returns void
   */
  requestTemplateSave: () => dispatch(saveDraftTemplateRequest(nodeType)),
  /**
   * Dispatches request to set template draft
   *
   * @param templateId Template id to set
   */
  requestTemplateSet: templateId => {
    dispatch(setDraftTemplateRequest(nodeType, templateId));
  },
});

/**
 * Map Redux app state to component props
 *
 * @param appState          Current app state
 * @param appState.debug    Debug state of the app
 * @param appState.drafts   Drafts in state
 * @param ownProps          Passed props
 * @param ownProps.nodeType The id of the draft
 * @returns                 Mapped state
 */
export const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = ({ debug, drafts }, { nodeType }) => ({
  dataTemplateCurrent: drafts.get(nodeType)?.templateCurrent ?? null,
  dataTemplateSaved: drafts.get(nodeType)?.templateSaved ?? null,
  dataTemplateSearchValue: drafts.get(nodeType)?.templateSearch ?? '',
  debugLoading: debug.networkCompose,
});
