/**
 * @file Label proxy links mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorChatConversation } from './navigators';

const mapperChat = {
  /** @todo Use NotificationEvent when and if CHAT__MESSAGE__CREATED is added to it. */
  [NotificationEvent.CHAT__MESSAGE__CREATED]: navigatorChatConversation,
};

export default mapperChat;
