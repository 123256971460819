import { CircularProgress } from '@mui/material';
import React, { FC } from 'react';

const CIRCULAR_PROGRESS_SIZE = 20;

/**
 * Loading indicator component
 *
 * @returns The component itself
 */
const LoadingIndicator: FC = () => {
  return <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />;
};

export default LoadingIndicator;
