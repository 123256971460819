import React, { FC, useCallback } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { useSubscription } from 'urql';

import {
  ActionType,
  LabelInfoDocument,
  Message,
  TemplateInfoDocument,
  Topic,
  TopicInfoDocument,
  UserGroupInfoDocument,
} from '../../generated/graphql';
import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import useParamMessageId from '../../hooks/router/params/useParamMessageId';
import useParamTopicId from '../../hooks/router/params/useParamTopicId';
import AppState from '../../models/state';
import { SubscriptionsAction } from '../../models/subscriptions';
import { getHomeUrl } from '../../routes/urls/home';

import { unsubscribeFromMessagesSet } from '../../store/actions/subscriptions';

import CommentInfoSubscription from './commentInfo';
import MessageInfoSubscription from './message/messageInfo';
import useActionDeleted from './useActionDeleted';

type DispatchProps = {
  requestUnsubscribeFromMessages: (topicId: Topic['id']) => void;
};

type StateProps = {
  messageIds: Message['id'][];
  topicIds: Topic['id'][];
};

type Props = DispatchProps & StateProps;

/**
 * App wide subscriptions container.
 *
 * @todo - At the moment, we are rendering a ghost component for each subscription
 * that returns null. It would be best to refactor this to trigger subs without React bindings
 * and not to have to render a certain amount of components and just have subscriptions active.
 * @param props                                Props passed to the component
 * @param props.messageIds                     Ids of the messages to use when subscribing to comments
 * @param props.requestUnsubscribeFromMessages Redux action that unsubscribes user from messages
 * @param props.topicIds                       IDs of the topics to use when subscribing to messages
 * @returns                                    Components that subscribe to data app-wide
 */
const SubscriptionsContainer: FC<Props> = ({
  messageIds,
  requestUnsubscribeFromMessages,
  topicIds,
}) => {
  const hotelId = useParamHotelId();
  const topicId = useParamTopicId();
  const messageId = useParamMessageId();
  /**
   * Subscribe to topics
   */
  const [{ data }] = useSubscription({ query: TopicInfoDocument });

  /**
   * Subscribe to userGroups
   */
  useSubscription({ query: UserGroupInfoDocument });

  /**
   * Subscribe to labels
   */
  useSubscription({ query: LabelInfoDocument });

  /**
   * Subscribe to templates
   */
  useSubscription({ query: TemplateInfoDocument });

  const isActionDeleted = data?.topicInfo.action === ActionType.DELETED;

  const unsubscribeAction = useCallback(() => {
    if (data?.topicInfo.id !== undefined) {
      requestUnsubscribeFromMessages(data?.topicInfo.id);
    }
  }, [data?.topicInfo.id, requestUnsubscribeFromMessages]);

  useActionDeleted({
    redirectUrl:
      isActionDeleted && topicId === data?.topicInfo.id
        ? getHomeUrl(hotelId)
        : null,
    unsubscribeAction: isActionDeleted ? unsubscribeAction : null,
  });

  return (
    <>
      {/* Subscribe to comments */}
      {messageIds.map(singleMessageId => (
        <CommentInfoSubscription
          key={singleMessageId}
          messageId={singleMessageId}
        />
      ))}

      {/* Subscribe to messages */}
      {topicIds.map(singleTopicId => (
        <MessageInfoSubscription
          key={singleTopicId}
          messageId={messageId}
          topicId={singleTopicId}
        />
      ))}
    </>
  );
};

/**
 * Add Redux dispatch actions to subscriptions props
 *
 * @param dispatch Redux dispatch callback
 * @returns        The props to add
 */
const mapDispatchToProps: MapDispatchToProps<DispatchProps, never> = (
  dispatch: Dispatch<SubscriptionsAction>,
) => ({
  requestUnsubscribeFromMessages: (topicId: Topic['id']) =>
    dispatch(unsubscribeFromMessagesSet(topicId)),
});

/**
 * Map global app state to what the container needs
 *
 * @param state Current global app state
 * @returns     Slice of the global state needed for the container
 */
const mapStateToProps: MapStateToProps<
  StateProps,
  Record<string, never>,
  AppState
> = state => ({
  messageIds: state.subscriptions.messageIds,
  topicIds: state.subscriptions.topicIds,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionsContainer);
