/**
 * @file Redux mappers for composeMetaOffset container
 */

import { MapDispatchToProps, MapStateToProps } from 'react-redux';

import AppState from '../../../models/state';
import {
  saveDraftOffsetRequest,
  setDraftOffsetRequest,
} from '../../../store/actions/draftsOffset';

import {
  addDraftRemindersRequest,
  removeDraftRemindersRequest,
  saveDraftRemindersRequest,
  setDraftRemindersRequest,
} from '../../../store/actions/draftsReminders';

import { DispatchProps, OwnProps, StateProps } from './types';

/**
 * Map Redux action dispatch to component props
 *
 * @param dispatch          Redux action dispatcher
 * @param ownProps          Passed props
 * @param ownProps.nodeType The id of the draft
 * @returns                 Mapped state
 */
export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  { nodeType },
) => ({
  /**
   * Dispatches request to remove offset draft
   */
  requestOffsetRemove: () => {
    dispatch(setDraftOffsetRequest(nodeType, null));
  },
  /**
   * Dispatches request to save offset draft
   */
  requestOffsetSave: () => {
    dispatch(saveDraftOffsetRequest(nodeType));
  },
  /**
   * Dispatches request to set offset draft
   *
   * @param offset Offset to be set
   */
  requestOffsetSet: offset => {
    dispatch(setDraftOffsetRequest(nodeType, offset));
  },
  /**
   * Dispatches request to add reminders draft
   *
   * @param reminder Reminder to be added
   */
  requestRemindersAdd: reminder => {
    dispatch(addDraftRemindersRequest(nodeType, reminder));
  },
  /**
   * Dispatches request to remove reminders draft
   *
   * @param position Position of the reminder to be removed
   */
  requestRemindersRemove: position => {
    dispatch(removeDraftRemindersRequest(nodeType, position));
  },
  /**
   * Dispatches request to save reminders draft
   */
  requestRemindersSave: () => {
    dispatch(saveDraftRemindersRequest(nodeType));
  },
  /**
   * Dispatches request to set reminders draft
   *
   * @param position Position of the reminder to be set
   * @param reminder Reminder to be set
   */
  requestRemindersSet: (position, reminder) => {
    dispatch(setDraftRemindersRequest(nodeType, position, reminder));
  },
});

/**
 * Map Redux app state to component props
 *
 * @param appState          Current app state
 * @param appState.debug    Debug state of the app
 * @param appState.drafts   Drafts in the state
 * @param ownProps          Passed props
 * @param ownProps.nodeType The id of the draft
 * @returns                 Mapped state
 */
export const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = ({ debug, drafts }, { nodeType }) => ({
  dataOffsetCurrent: drafts.get(nodeType)?.offsetCurrent ?? null,
  dataOffsetSaved: drafts.get(nodeType)?.offsetSaved ?? null,
  dataRemindersCurrent: drafts.get(nodeType)?.remindersCurrent ?? [],
  dataRemindersSaved: drafts.get(nodeType)?.remindersSaved ?? [],
  debugLoading: debug.networkCompose,
});
