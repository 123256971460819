/**
 * @file Hook for handling attachment action (download/preview)
 */

import { RefObject, useEffect, useState } from 'react';

import { File as CustomFile, Message } from '../../generated/graphql';

import {
  AttachmentAction,
  AttachmentActionType,
} from '../../models/attachment';

import useAttachmentGetDownloadUrl from './useAttachmentGetDownloadUrl';
import useAttachmentPreview from './useAttachmentPreview';
import useTrackAttachmentAction from './useTrackAttachmentAction';

/**
 * Hook for handling attachment action (download/preview)
 *
 * @param actionType Type of action (download/preview)
 * @param filename   File name
 * @param key        Key of the attachment to download/preview
 * @param linkRef    Anchor element used for attachment action
 * @param messageId  Id of the current message
 * @returns          Object with necessary props for handling attachment action
 */
const useAttachmentAction = (
  actionType: AttachmentActionType,
  filename: string,
  key: CustomFile['key'],
  linkRef: RefObject<HTMLAnchorElement>,
  messageId: Message['id'],
): AttachmentAction => {
  const [link, setLink] = useState<string>();
  const [shouldExecute, setShouldExecute] = useState<boolean>(false);
  const handleFilePreview = useAttachmentPreview(setLink);

  const {
    queryData: { data, error, fetching },
    reExecuteQuery,
  } = useAttachmentGetDownloadUrl(actionType, key, messageId);

  const trackAttachmentAction = useTrackAttachmentAction(
    actionType,
    filename,
    messageId,
  );

  /**
   * Requests to download/preview an attachment and tracks the action
   */
  const requestButtonClick = () => {
    trackAttachmentAction();
    setShouldExecute(true);
    reExecuteQuery();
  };

  useEffect(() => {
    if (
      data !== undefined &&
      error === undefined &&
      fetching === false &&
      shouldExecute === true
    ) {
      if (actionType === 'preview') {
        handleFilePreview(filename, data.fileSignedDownloadUrlForMessage);
        return;
      }

      setLink(data.fileSignedDownloadUrlForMessage);
    }

    // Only run this effect on data.fileSignedDownloadUrlForMessage change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.fileSignedDownloadUrlForMessage]);

  useEffect(() => {
    setShouldExecute(false);

    if (link === undefined || linkRef.current === null) {
      return;
    }

    if (link !== '') {
      linkRef.current.click();
    }

    setLink(undefined);

    // Only run this effect on link change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  return {
    isInProgress: shouldExecute,
    link,
    linkRef,
    requestButtonClick,
  };
};

export default useAttachmentAction;
