/**
 * @file Map Redux dispatch to component props
 */

import { MapDispatchToProps } from 'react-redux';
import { Dispatch } from 'redux';

import { ToastMessageAction } from '../../models/toastMessage';
import {
  addToastMessage,
  removeToastMessage,
  setDurationToastMessage,
} from '../../store/actions/toastMessage';

import { DispatchProps } from './props';

/**
 * Map Redux dispatch to component props
 *
 * @param dispatch Redux dispatch callback
 * @returns        Props to inject into the component
 */
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  Record<string, never>
> = (dispatch: Dispatch<ToastMessageAction>) => ({
  /**
   * Dispatches request to add toast message
   *
   * @param state Message to add
   */
  requestAddToastMessage: state => {
    dispatch(addToastMessage(state));
  },
  /**
   * Dispatches request to remove toast message
   *
   * @param state Id of the message to remove
   */
  requestRemoveToastMessage: state => {
    dispatch(removeToastMessage(state));
  },
  /**
   * Dispatches request to set toast message duration
   *
   * @param state Message with duration to set
   */
  requestSetToastMessageDuration: state => {
    dispatch(setDurationToastMessage(state));
  },
});

export default mapDispatchToProps;
