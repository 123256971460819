import React, { FC, PropsWithChildren, useId } from 'react';

import translate from '../../../i18n/translate';
import { InputFileProps } from '../../../models/attachment';

import * as Styled from './styled';

export type Props = {
  className?: string;
  inputFileProps: InputFileProps;
} & PropsWithChildren;

/**
 * The input for attaching a file
 *
 * @param props                Props passed to the component
 * @param props.className      The class name to be added to the component
 * @param props.children       The children to be rendered inside the component
 * @param props.inputFileProps Props passed to the <input type="file" />
 * @returns                    The component itself
 */
const InputFileAttachment: FC<Props> = ({
  children,
  className,
  inputFileProps,
}) => {
  const inputId = useId();

  return (
    <div className={className}>
      <Styled.FileUploadLabel
        aria-label={translate('ATTACHMENT__EMPTY__TITLE')}
        htmlFor={inputId}
      >
        {children}
      </Styled.FileUploadLabel>
      <Styled.FileUploadInput id={inputId} type="file" {...inputFileProps} />
    </div>
  );
};

export default InputFileAttachment;
