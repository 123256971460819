/**
 * @file Label proxy links mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorLabel } from './navigators';

const mapperLabel = {
  [NotificationEvent.LABEL__CREATED]: navigatorLabel,
  [NotificationEvent.LABEL__DELETED]: navigatorLabel,
  [NotificationEvent.LABEL__UPDATED]: navigatorLabel,
};

export default mapperLabel;
