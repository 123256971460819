/**
 * @file Create new gql fragment for Message and update cache
 */

import { Cache } from '@urql/exchange-graphcache';

import {
  MessageFeedFragment,
  MessageFeedFragmentDoc,
  MessagesReadMutationVariables,
  TopicsUnreadDocument,
  TopicsUnreadQuery,
  TopicsUnreadQueryVariables,
} from '../../../../../../../generated/graphql';

/**
 * Create new gql fragment for Message and update cache
 *
 * @param args  The arguments passed along with the mutation
 * @param cache Current GraphQL cache
 */
export const messagesSetSeen = (
  args: MessagesReadMutationVariables,
  cache: Cache,
): void => {
  // The mutation supports both a scalar and an array of message IDs
  // For us, it's easier if we normalize it to an array
  const messageIds = Array.isArray(args.messageIds)
    ? args.messageIds
    : [args.messageIds];

  messageIds.forEach(messageId => {
    const message = cache.readFragment<
      MessageFeedFragment,
      MessageFeedFragment
    >(MessageFeedFragmentDoc, {
      __typename: 'Message',
      id: messageId,
    });

    // If the message was already seen, we don't need to update the cache
    if (message === null || message.isSeen === true) {
      return;
    }

    // Update the message isSeen flag
    cache.writeFragment(MessageFeedFragmentDoc, { ...message, isSeen: true });

    // Update the unread messages count for the topic
    // Without this, the red dot on home icon on mobile won't disappear
    cache.updateQuery<TopicsUnreadQuery, TopicsUnreadQueryVariables>(
      {
        query: TopicsUnreadDocument,
        variables: {},
      },
      data => {
        if (data === null) {
          return null;
        }

        data.topics = data.topics.map(topic => {
          if (topic.id === message.topic.id) {
            topic.unreadMessages -= 1;
          }

          return topic;
        });

        return data;
      },
    );
  });
};
