import React, { FC, useCallback } from 'react';

import useIsMobile from '../../../hooks/useIsMobile';
import translate from '../../../i18n/translate';
import { Comment } from '../../../models/comment';

import CommentSingle from '../CommentSingle';
import * as Styled from '../styled';

import useCommentsCurrentCursor from './useCommentsCurrentCursor';

export type Props = {
  canReply: boolean;
  comments: Comment[];
  isLoading: boolean;
  isNewComment: boolean;
};

/**
 * A list of comments for a message
 *
 * @param props              Props passed to the component
 * @param props.canReply     Whether the user can reply
 * @param props.comments     The comments to show
 * @param props.isNewComment Whether new comment was created by the user
 * @param props.isLoading    Whether the comments are being fetched
 * @returns                  The component itself
 */
const CommentList: FC<Props> = ({
  canReply,
  comments,
  isLoading,
  isNewComment,
}) => {
  const isMobile = useIsMobile();

  const currentCursor = useCommentsCurrentCursor(comments, isNewComment);

  const firstCommentRef = useCallback(
    (node: HTMLLIElement) => {
      if (isMobile && node !== null) {
        node.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
    },
    [isMobile],
  );

  if (isMobile && comments.length === 0 && isLoading === false) {
    return (
      <Styled.WrapperNoComments>
        {translate(
          canReply ? 'COMMENTS__EMPTY' : 'COMMENTS__EMPTY_LIMITED_PERMISSION',
        )}
      </Styled.WrapperNoComments>
    );
  }

  return (
    <ul>
      {comments.map(comment => {
        return (
          <li
            key={comment.id}
            ref={currentCursor === comment.id ? firstCommentRef : undefined}
          >
            <CommentSingle comment={comment} />
          </li>
        );
      })}
    </ul>
  );
};

export default CommentList;
