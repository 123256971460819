import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import OpenComposeButton from '../../components/OpenComposeButton';

import useAttachmentDraftState from '../../hooks/attachments/useAttachmentDraftState';
import useParamTemplateId from '../../hooks/router/params/useParamTemplateId';

import { getOpenComposeUrl } from '../../routes/urls/searchParams/compose';
import { clearDraftRequest } from '../../store/actions/draftsMain';

type Props = {
  requestDraftClear: () => void;
};

/**
 * Button that opens compose popup on mobiles
 *
 * @param props                   Props passed to component
 * @param props.requestDraftClear Clears compose form data for templates
 * @returns                       Button react component
 */
const OpenComposeButtonContainer: FC<Props> = ({ requestDraftClear }) => {
  const location = useLocation();
  const nodeId = useParamTemplateId();
  const urlClosed = getOpenComposeUrl(location).replace('/' + nodeId, '');

  const { clearDraft } = useAttachmentDraftState('TEMPLATE');

  /**
   * Handles click inside OpenComposeButton component
   * Clears template and attachments draft data
   */
  const handleClick = () => {
    clearDraft();
    requestDraftClear();
  };
  return <OpenComposeButton handleClick={handleClick} url={urlClosed} />;
};

export default connect(null, dispatch => ({
  /**
   * Dispatches request to clear draft
   *
   * @returns void
   */
  requestDraftClear: () => dispatch(clearDraftRequest('TEMPLATE')),
}))(OpenComposeButtonContainer);
