import { ButtonProps } from '@mui/material';
import Tippy from '@tippyjs/react';
import React, { FC, useCallback } from 'react';

import translate from '../../i18n/translate';
import { getCanChangeTargetLanguage } from '../../utils/permissions/user';

import SpinnerDots from '../Common/SpinnerDots';

import MuiIcon from '../MUI/MuiIcon';

import * as Styled from './styled';

export type Props = {
  className?: string;
  isActive: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
  targetLanguage: string;
};

/**
 * Button used for text translation
 *
 * @param props                Props passed to the component
 * @param props.className      styled-components generated class name, needed for styling
 * @param props.isActive       Whether the button is active (message is translated)
 * @param props.isDisabled     Whether the button is disabled
 * @param props.isLoading      Whether the button is in the loading state
 * @param props.onClick        Mouse click handler
 * @param props.targetLanguage User target language code
 * @returns                    The component itself
 */
const ButtonTranslate: FC<Props> = ({
  className,
  isActive,
  isDisabled = false,
  isLoading = false,
  onClick,
  targetLanguage,
}) => {
  const label = translate('ARIA__LABEL__TRANSLATE');

  const tooltip = translate(
    getCanChangeTargetLanguage()
      ? 'TRANSLATE__BUTTON'
      : 'TRANSLATE__BUTTON__LIMITED_PERMISSION',
  );

  /**
   * Get icons for translate button
   *
   * @returns Icons for translate button as props object
   */
  const getTranslateButtonIcons = useCallback((): Pick<
    ButtonProps,
    'children' | 'endIcon' | 'startIcon'
  > => {
    if (isLoading) {
      return { children: <SpinnerDots /> };
    }

    return {
      children: (
        <MuiIcon
          fontSize="small"
          icon={isActive ? 'arrowLeft' : 'arrowRight'}
        />
      ),
      endIcon: targetLanguage,
      startIcon: <MuiIcon icon="translate" />,
    };
  }, [isActive, isLoading, targetLanguage]);

  return (
    <Tippy content={tooltip} touch={false}>
      <Styled.Button
        {...getTranslateButtonIcons()}
        aria-label={label}
        className={className}
        color={isActive ? 'neutral' : 'tertiary'}
        disabled={isDisabled}
        onClick={onClick}
        variant="contained"
      />
    </Tippy>
  );
};

export default ButtonTranslate;
