// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --preference-compose-toggle-closed-height: 4rem;
  --preference-left-sidebar-closed-width-desktop: 80px;
  --preference-left-sidebar-open-width-desktop: 440px;
  --preference-message-line-clamp: 3;
  --preference-label-line-clamp: 2;
  --preference-button-line-clamp: 1;
  --preference-loading-indicator-size: 40px;
  --preference-right-sidebar-open-max-width-desktop: 500px;
  --preference-right-sidebar-open-max-width-tablet: 300px;
  --preference-right-sidebar-open-min-width-desktop: 250px;
  --preference-right-sidebar-open-width-desktop: 20vw;
  --preference-right-sidebar-open-width-tablet: 40vw;
  --preference-search-width-desktop: 720px;
  --preference-segmented-button-width-desktop: 210px;
  --preference-sidebar-triangle-position-top: 15%;
  --preference-sidebar-triangle-width: 11px;
  --preference-topic-header-height: 55px;
  --preference-select-item-min-height: 56px;
  --preference-transition-duration-long: 1s;
  --preference-transition-duration-normal: 0.3s;
  --preference-transition-duration-short: 0.1s;
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/_preferences.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,oDAAoD;EACpD,mDAAmD;EACnD,kCAAkC;EAClC,gCAAgC;EAChC,iCAAiC;EACjC,yCAAyC;EACzC,wDAAwD;EACxD,uDAAuD;EACvD,wDAAwD;EACxD,mDAAmD;EACnD,kDAAkD;EAClD,wCAAwC;EACxC,kDAAkD;EAClD,+CAA+C;EAC/C,yCAAyC;EACzC,sCAAsC;EACtC,yCAAyC;EACzC,yCAAyC;EACzC,6CAA6C;EAC7C,4CAA4C;AAC9C","sourcesContent":[":root {\n  --preference-compose-toggle-closed-height: 4rem;\n  --preference-left-sidebar-closed-width-desktop: 80px;\n  --preference-left-sidebar-open-width-desktop: 440px;\n  --preference-message-line-clamp: 3;\n  --preference-label-line-clamp: 2;\n  --preference-button-line-clamp: 1;\n  --preference-loading-indicator-size: 40px;\n  --preference-right-sidebar-open-max-width-desktop: 500px;\n  --preference-right-sidebar-open-max-width-tablet: 300px;\n  --preference-right-sidebar-open-min-width-desktop: 250px;\n  --preference-right-sidebar-open-width-desktop: 20vw;\n  --preference-right-sidebar-open-width-tablet: 40vw;\n  --preference-search-width-desktop: 720px;\n  --preference-segmented-button-width-desktop: 210px;\n  --preference-sidebar-triangle-position-top: 15%;\n  --preference-sidebar-triangle-width: 11px;\n  --preference-topic-header-height: 55px;\n  --preference-select-item-min-height: 56px;\n  --preference-transition-duration-long: 1s;\n  --preference-transition-duration-normal: 0.3s;\n  --preference-transition-duration-short: 0.1s;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
