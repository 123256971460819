/**
 * @file Material UI utilities
 */

import { Link } from 'react-router-dom';

import {
  EXTERNAL_LINK_PROPS,
  ROUTER_LINK_PROPS,
} from '../constants/routing/linkProps';

/**
 * Get the props for a polymorphic link component
 *
 * @param options                Options for the link
 * @param options.isExternal     If the link is external
 * @param options.reloadDocument If the link should reload the document
 * @param options.shouldReplace  If the link should replace the current history entry
 * @param options.url            The url to link to
 * @returns                      Props for the polymorphic link component
 */
export const getPolymorphicLinkProps = ({
  isExternal,
  reloadDocument,
  shouldReplace,
  url,
}: {
  isExternal: boolean;
  reloadDocument?: boolean;
  shouldReplace?: boolean;
  url: string;
}) => {
  return isExternal
    ? {
        component: 'a',
        href: url,
        ...EXTERNAL_LINK_PROPS,
      }
    : {
        component: Link,
        replace: shouldReplace,
        to: url,
        ...ROUTER_LINK_PROPS,
        reloadDocument,
      };
};
