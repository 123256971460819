import React, { FC } from 'react';

import { HASH_MESSAGE__INFO__DUE_DATE } from '../../../../constants/routing/hashLinks';

import { MessageStatus } from '../../../../generated/graphql';
import { MessageCommon } from '../../../../models/message';
import { dueDateFormatUi } from '../../../../utils/date/format';
import Icon, { NAMES } from '../../Icon';
import MessageInfo from '../MessageInfo';

import * as Styled from './styled';

type Props = {
  dueDate: MessageCommon['dueDate'];
  messageId: MessageCommon['id'];
  shouldBeLink: boolean;
  status: MessageCommon['status'];
};

const ICON_SIZE = 17;

/**
 * Shows info about message due date
 *
 * @param props              Props passed to the component
 * @param props.messageId    Message id
 * @param props.dueDate      Message due date
 * @param props.shouldBeLink Whether this component should be rendered as a link
 * @param props.status       Current status value
 * @returns                  The component itself
 */
const MessageInfoDueDate: FC<Props> = ({
  dueDate = null,
  messageId,
  shouldBeLink,
  status,
}) => {
  if (dueDate === null) return null;

  const dateObj = new Date(dueDate);
  const isLate = dateObj.getTime() < Date.now();
  const isResolved = status === MessageStatus.RESOLVED;

  return (
    <MessageInfo
      elementId={HASH_MESSAGE__INFO__DUE_DATE}
      labelKey="ARIA__MESSAGE_INFO__DUE_DATE"
      messageId={messageId}
      shouldBeLink={shouldBeLink}
    >
      <Icon height={ICON_SIZE} name={NAMES.GENERAL__CLOCK} width={ICON_SIZE} />
      <Styled.Time
        data-is-late={isLate}
        data-is-resolved={isResolved}
        dateTime={dueDate}
      >
        {dueDateFormatUi(dueDate)}
      </Styled.Time>
    </MessageInfo>
  );
};

export default MessageInfoDueDate;
