/**
 * @file Hook for managing tasks local state
 */

import { OnDragEndResponder } from '@hello-pangea/dnd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import { PAGINATION__MAX_ITEMS_PER_PAGE } from '../../constants/forms';
import { TASK_QUERY_ALIASES_MAP } from '../../constants/message';
import {
  MessageStatus,
  MessageStatusUpdateDocument,
  TasksQuery,
  TasksQueryVariables,
} from '../../generated/graphql';
import useParamTopicId from '../../hooks/router/params/useParamTopicId';
import { MessageFeed, TasksQueryAlias } from '../../models/message';
import { getSerializedFilters } from '../../routes/helpers/filters';
import { reportApiErrors } from '../../utils/error';
import {
  getTasksQueryOptions,
  insertTaskByDueDate,
} from '../../utils/message/tasks';

type State = Record<TasksQueryAlias, { nodes: MessageFeed[]; total: number }>;

/**
 * Transforms queries data to local state object
 *
 * @param queries Tasks queries record
 * @returns       A record of task aliases with tasks and total tasks count
 */
const buildTasksState = (
  queries: Record<
    TasksQueryAlias,
    ReturnType<typeof useQuery<TasksQuery, TasksQueryVariables>>[0]['data']
  >,
): State => {
  return Object.keys(queries).reduce(
    (state, queryAlias: TasksQueryAlias) => {
      state[queryAlias].nodes = (queries[queryAlias]?.tasks.nodes ??
        []) as MessageFeed[];
      state[queryAlias].total = queries[queryAlias]?.tasks.nodesInfo.total ?? 0;
      return state;
    },
    {
      tasksInProgress: {
        nodes: [],
        total: 0,
      },
      tasksOpen: {
        nodes: [],
        total: 0,
      },
      tasksResolved: {
        nodes: [],
        total: 0,
      },
    } as State,
  );
};

/**
 * Hook for managing tasks state within the kanban
 *
 * @returns Object containing data, fetching state and drop responder
 */
export const useTasks = () => {
  const location = useLocation();
  const topicId = useParamTopicId();

  const [, messageStatusUpdateMutation] = useMutation(
    MessageStatusUpdateDocument,
  );

  const serializedFilters = getSerializedFilters(location);
  const filterMembers = Array.from(serializedFilters.members);

  const [tasksInProgressQuery, refetchInProgressTasks] = useQuery(
    getTasksQueryOptions(topicId, MessageStatus.IN_PROGRESS, filterMembers),
  );
  const [tasksOpenQuery, refetchOpenTasks] = useQuery(
    getTasksQueryOptions(topicId, MessageStatus.OPEN, filterMembers),
  );
  const [tasksResolvedQuery, refetchResolvedTasks] = useQuery(
    getTasksQueryOptions(topicId, MessageStatus.RESOLVED, filterMembers),
  );

  const [data, setData] = useState<State>(
    buildTasksState({
      tasksInProgress: tasksInProgressQuery.data,
      tasksOpen: tasksOpenQuery.data,
      tasksResolved: tasksResolvedQuery.data,
    }),
  );

  const isFetching =
    tasksInProgressQuery.fetching ||
    tasksOpenQuery.fetching ||
    tasksResolvedQuery.fetching;

  /**
   * Keeps the local state up-to-date with any updates that occur
   */
  useEffect(() => {
    if (isFetching === true) {
      return;
    }

    setData(
      buildTasksState({
        tasksInProgress: tasksInProgressQuery.data,
        tasksOpen: tasksOpenQuery.data,
        tasksResolved: tasksResolvedQuery.data,
      }),
    );
  }, [
    isFetching,
    tasksInProgressQuery.data,
    tasksOpenQuery.data,
    tasksResolvedQuery.data,
  ]);

  /**
   * Refetch tasks by status
   *
   * @param status Status of tasks to refetch
   */
  const refetchTasks = (status: MessageStatus) => {
    const mapping = {
      [MessageStatus.IN_PROGRESS]: refetchInProgressTasks,
      [MessageStatus.OPEN]: refetchOpenTasks,
      [MessageStatus.RESOLVED]: refetchResolvedTasks,
    };

    // There will be already tasks in cache, refetch to update
    mapping[status]({ requestPolicy: 'cache-and-network' });
  };

  /**
   * Drag end event handler
   *
   * @param event Drag event
   */
  const requestMoveTask: OnDragEndResponder = event => {
    const { destination, draggableId: taskId, source } = event;

    if (destination === null) {
      return;
    }

    const destinationStatus = destination.droppableId as MessageStatus;
    const sourceStatus = source.droppableId as MessageStatus;

    if (sourceStatus === destinationStatus) {
      return;
    }

    setData(prev => {
      const destinationAlias = TASK_QUERY_ALIASES_MAP[destinationStatus];
      const nextState = structuredClone(prev);
      const sourceAlias = TASK_QUERY_ALIASES_MAP[sourceStatus];

      // Remove and extract node from source section
      const task = nextState[sourceAlias].nodes.splice(source.index, 1)[0];

      if (task === undefined) {
        reportError(`useTasks: Task with id ${taskId} not found`);
        return prev;
      }

      const updatedTask = {
        ...task,
        status: destinationStatus,
      };

      // If source column has more than PAGINATION__MAX_ITEMS_PER_PAGE,
      // and there are less than that number of items currently in the column,
      // refetch tasks for that column to keep column up to date
      if (
        nextState[sourceAlias].total > PAGINATION__MAX_ITEMS_PER_PAGE &&
        nextState[sourceAlias].nodes.length < PAGINATION__MAX_ITEMS_PER_PAGE
      ) {
        refetchTasks(sourceStatus);
      }

      nextState[sourceAlias].total--;

      // Add node to destination section
      nextState[destinationAlias].nodes = insertTaskByDueDate(
        updatedTask,
        nextState[destinationAlias].nodes,
      );
      nextState[destinationAlias].total++;

      return nextState;
    });

    messageStatusUpdateMutation({
      id: taskId,
      status: destinationStatus,
    })
      .then(result => reportApiErrors(result.error))
      .catch(reportApiErrors);
  };

  return {
    data,
    isFetching,
    requestMoveTask,
  };
};
