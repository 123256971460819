import React, { FC, MutableRefObject, ReactNode, useRef } from 'react';

import DevPanel from '../../../containers/devPanel';
import LeftSidebarContainer from '../../../containers/leftSidebar';
import NotificationsMain from '../../../containers/modals/Notifications/Main';
import SubscriptionsContainer from '../../../containers/subscriptions';
import ToastMessages from '../../../containers/toastMessages';
import useIsMobile from '../../../hooks/useIsMobile';

import usePullToRefresh from '../../../hooks/usePullToRefresh';
import { getCanViewDevPanel } from '../../../utils/permissions/devPanel';

import PullToRefreshIndicator from '../../PullToRefreshIndicator';

import Footer from './Footer';
import { getToastMessageFooter, getToastMessageHeader } from './helpers';
import * as Styled from './styled';
import { MainProps } from './styledProps';
import useSidebarsClickOutside from './useSidebarsClickOutside';

export type Props = {
  children: ReactNode;
  className?: string;
  direction?: MainProps['data-direction'];
  footer?: ReactNode;
  header?: ReactNode;
  height?: MainProps['data-height'];
  mainAbsoluteElements?: ReactNode;
  mainRef?: MutableRefObject<HTMLElement | null>;
  rightSidebar?: {
    clickOutsideOptions?: {
      onClick: () => void;
      stopExec: boolean;
    };
    component: ReactNode;
    shouldShowDesktop: boolean;
    shouldShowMobile: boolean;
  } | null;
};

/**
 * AppLayout component
 * Draws out the main app layout for mobile and desktop. It expects components that are layout parts
 * to be passed in. It consists of header, footer, subheader and left and right sidebars. Sidebars on
 * the desktop and mobile are retractable. Almost every prop is optional.
 *
 * @param props                      Props passed to the component
 * @param props.children             Main app contents
 * @param props.className            Passed so we can use component in styled-components
 * @param props.direction            Determine direction of content inside of main
 * @param props.footer               Footer component
 * @param props.header               Header component
 * @param props.height               Height value used for main
 * @param props.mainAbsoluteElements Elements absolutely positioned against Main without scrolling in case we need to
 * @param props.mainRef              Reference used for scroll inside of main
 * @param props.rightSidebar         Right sidebar component
 * @returns                          The component itself
 */
const AppLayout: FC<Props> = ({
  children,
  className,
  direction = 'normal',
  footer = null,
  header,
  height = 'auto',
  mainAbsoluteElements = null,
  mainRef = null,
  rightSidebar = null,
}) => {
  const isMobile = useIsMobile();
  const shouldEnableDevPanel = getCanViewDevPanel();

  const rightSidebarRef = useRef<HTMLDivElement>(null);

  useSidebarsClickOutside({
    rightSidebarOptions: rightSidebar?.clickOutsideOptions,
    rightSidebarRef,
  });

  const { isRefreshing, shouldShowIndicator, ...touchEvents } =
    usePullToRefresh();

  return (
    <Styled.Wrapper className={className}>
      {shouldShowIndicator && (
        <PullToRefreshIndicator isRefreshing={isRefreshing} />
      )}
      <Styled.Header {...touchEvents}>
        {header}
        {getToastMessageHeader(<ToastMessages />, isMobile)}
      </Styled.Header>
      <LeftSidebarContainer />
      <Styled.MainContainer>
        <Styled.Main
          data-direction={direction}
          data-height={height}
          ref={mainRef}
        >
          <SubscriptionsContainer />
          {children}
        </Styled.Main>
        {mainAbsoluteElements}
      </Styled.MainContainer>

      {rightSidebar !== null && (
        <Styled.RightAside
          data-should-show-desktop={rightSidebar.shouldShowDesktop}
          ref={rightSidebarRef}
        >
          <Styled.RightAsideComponentWrapper
            data-should-show-desktop={rightSidebar.shouldShowDesktop}
            data-should-show-mobile={rightSidebar.shouldShowMobile}
          >
            {rightSidebar.component}
          </Styled.RightAsideComponentWrapper>
        </Styled.RightAside>
      )}
      <Footer>{footer}</Footer>
      {shouldEnableDevPanel && <DevPanel showInline={false} />}
      <NotificationsMain />
      {getToastMessageFooter(<ToastMessages />, isMobile)}
    </Styled.Wrapper>
  );
};

export default AppLayout;
