/**
 * @file Reminder proxy links mapper
 */

import { NotificationEvent } from '../../../../generated/graphql';

import { navigatorMessage } from './navigators';

const mapperReminder = {
  [NotificationEvent.MESSAGE__REMINDERS__NOTIFICATION__FOR_ASSIGNEES]:
    navigatorMessage,
  [NotificationEvent.MESSAGE__REMINDERS__NOTIFICATION__FOR_CREATOR]:
    navigatorMessage,
  [NotificationEvent.MESSAGE__REMINDERS__NOTIFICATION]: navigatorMessage,
  [NotificationEvent.MESSAGE__REMINDERS__UPDATED__FOR_ASSIGNEES]:
    navigatorMessage,
  [NotificationEvent.MESSAGE__REMINDERS__UPDATED__FOR_CREATOR]:
    navigatorMessage,
  [NotificationEvent.MESSAGE__REMINDERS__UPDATED]: navigatorMessage,
};

export default mapperReminder;
