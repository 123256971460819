import { styled } from 'styled-components';

export const TitleComponentWrapper = styled.div`
  display: flex;
  justify-content: center;

  /* 80px = leftPadding(15px) + 2 x sideWidthMin +  rightPadding(15px) */
  max-width: calc(100vw - 80px);
  overflow: hidden;

  /* Disable text selection on the title to prevent the title from being selected when the user triggers pull-to-refresh */
  user-select: none;
`;
