/**
 * @file Optimistically update the cache, filtering out archived messages
 */

import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import {
  MessageArchiveMutation,
  MessagesDocument,
  MutationMessageArchiveArgs,
} from '../../../../../generated/graphql';
import paginatedQueryDeletion from '../../updates/utils/updaters/paginatedQueryDeletion';
import { removeNodeFromPaginatedQuery } from '../../updates/utils/updaters/paginatedQueryHelpers';

/**
 * Optimistically archive a message
 *
 * @param args  Arguments of a graphQL operation
 * @param cache URQL graphQL cache
 * @returns     Updated cache
 */
const messageArchive: OptimisticMutationResolver<
  MutationMessageArchiveArgs,
  MessageArchiveMutation['messageArchive'] | null
> = (args, cache) => {
  paginatedQueryDeletion({
    cache,
    queryName: 'messages',
    /**
     * Remove node from paginated query
     *
     * @param variables Variables used in the query
     */
    updateQuery: variables => {
      removeNodeFromPaginatedQuery({
        cache,
        nodeId: args.id,
        query: MessagesDocument,
        queryName: 'messages',
        variables,
      });
    },
  });

  cache.invalidate({
    __typename: 'Message',
    id: args.id,
  });

  return {
    __typename: 'Message',
    id: args.id,
  };
};

export default messageArchive;
